import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import AccordianTable from 'src/components/AdminPage/Common/AccordianTable';
import { TableProps } from 'src/components/core/Table/Table';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { Community } from 'src/types/forum';
import CommunityRoleDropdown from '../../PillDropdowns/CommunityRoleDropdown';

import styles from './MemberCommunitiesTable.module.scss';

const CommunityNameContent = ({ val: community }: { val: Community }) => {
  return <span className={cx(styles.title)}>{community.name}</span>;
};

const CommunityRoleContent = ({ val: community }: { val: Community }) => {
  const currentMember = useSelector(adminSelectors.getCurrentUser);

  return (
    <>
      {currentMember && (
        <CommunityRoleDropdown
          communityId={community.id}
          member={currentMember}
        />
      )}
    </>
  );
};

const MemberCommunitiesTable = () => {
  const { t } = useTranslation();
  const communities = useSelector(adminSelectors.getCommunities);

  const tableProps: TableProps<Community> = {
    values: communities,
    testId: 'user-communities-table',
    valToKey: (community) => community.id,
    columns: [
      {
        id: 'community-name',
        headerLabel: t('admin.member_community_column_1'),
        widthFraction: 8,
        content: CommunityNameContent,
      },
      {
        id: 'community-role',
        headerLabel: t('admin.member_community_column_2'),
        widthFraction: 4,
        content: CommunityRoleContent,
      },
    ],
  };

  return (
    <AccordianTable
      {...tableProps}
      label={t('admin.community_accordian_label')}
    />
  );
};

export default MemberCommunitiesTable;
