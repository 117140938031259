import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface OrgRoleTooptipProps {
  width: string;
}

type Props = OrgRoleTooptipProps;

const CommunityRoleTooltip = ({ width }: Props) => {
  const [shopToolTip, setShowToolTip] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const ADMIN = React.useMemo(() => t('admin.organization_role_admin'), [t]);
  const ADMIN_DESCRIPTION = React.useMemo(
    () => t('admin.tab_team_tooltip_organization_role_admin'),
    [t]
  );
  const HOST_DESCRIPTION = React.useMemo(
    () => t('admin.tab_team_tooltip_organization_role_host'),
    [t]
  );
  const MEMBER = React.useMemo(() => t('admin.organization_role_member'), [t]);
  const MEMBER_DESCRIPTION = React.useMemo(
    () => t('admin.tab_team_tooltip_organization_role_member'),
    [t]
  );

  return (
    <>
      <div
        id="access-level-helper"
        tabIndex={0}
        style={{ width: '40px', marginLeft: '8px' }}
        aria-label={`${ADMIN} - ${ADMIN_DESCRIPTION}. ${MEMBER} - ${MEMBER_DESCRIPTION}`}
      >
        <FontAwesomeIcon
          icon={['fas', 'question-circle']}
          size="1x"
          color="gray"
        />
      </div>
      <Tooltip
        placement="right-start"
        hideArrow={true}
        isOpen={shopToolTip}
        innerClassName={'d-flex flex-column shadow'}
        style={{ width }}
        target={'access-level-helper'}
        toggle={() => setShowToolTip((self) => !self)}
        data-testid={`tooltip-access-level-helper`}
      >
        <span className={'w-100 text-start ms-1'}>{ADMIN_DESCRIPTION}</span>
        <span className={'w-100 text-start ms-1'}>{HOST_DESCRIPTION}</span>
        <span className={'w-100 text-start ms-1'}>{MEMBER_DESCRIPTION}</span>
      </Tooltip>
    </>
  );
};

export default CommunityRoleTooltip;
