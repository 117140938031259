// TODO: consume this places so we're not dupping this everyhwhere and we have one pattern to

import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import adminSelectors from 'src/redux/admin/admin-selectors';
import { userTransaction } from 'src/redux/admin/admin-slice';
import { generateTransaction } from 'src/redux/redux-helpers';
import { UserTransactions } from 'src/types/admin';

// align on
export const useUserTransaction = (
  onErr?: () => void,
  onSuccess?: () => void
) => {
  const dispatch = useDispatch();
  const [transaction, setTransaction] = useState<UserTransactions>();
  const transactions = useSelector(adminSelectors.getUserTransactions);

  useEffect(() => {
    if (transaction) {
      const matchingTransaction = transactions.filter(
        (item) => item.id === transaction.id
      )[0];

      if (!matchingTransaction) {
        onSuccess?.();
        setTransaction(undefined);
      } else {
        if (matchingTransaction.error) {
          onErr?.();
        }

        setTransaction(matchingTransaction);
      }
    }
  }, [transactions, transaction, onSuccess, onErr]);

  const startTransaction = useCallback(
    (t: UserTransactions) => {
      if (!t.id) {
        // honor id if caller has already set it
        t = generateTransaction(t);
      }

      setTransaction(t);
      dispatch(userTransaction(t));
    },
    [dispatch]
  );

  return {
    transactionError: transaction?.error,
    transactionLoading: !!transaction && !transaction.error,
    startTransaction,
  };
};
