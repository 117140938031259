import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import Toggle from 'src/components/core/Toggle/Toggle';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { userTransaction } from 'src/redux/admin/admin-slice';
import { generateTransaction } from 'src/redux/redux-helpers';
import { StoreState } from 'src/redux/store';
import { UserTransactions } from 'src/types/admin';
import { Collection, CollectionRole } from 'src/types/collection';
import { CRUD } from 'src/types/core';

import styles from './UploadConversationsToggle.module.scss';

interface UploadConversationsToggleProps {
  collection: Collection;
}

const UploadConversationsToggle = ({
  collection,
}: UploadConversationsToggleProps) => {
  const { t } = useTranslation();
  const UPLOADTOOLTIP = t('admin.tab_team_upload_conversations_tooltip');
  const dispatch = useDispatch();
  const [lockTooltip, setLockTooltip] = React.useState<boolean>(false);
  const [transaction, setTransaction] = React.useState<UserTransactions>();
  const currentMember = useSelector(adminSelectors.getCurrentUser);
  const iAmAdminOrOwner = useSelector(adminSelectors.iAmAdminOrOwner);
  const isAdminOrOwner = useSelector((state) =>
    adminSelectors.isAdminOrOwner(state as StoreState)
  );
  const transactions = useSelector((state) =>
    adminSelectors.getUserTransactionsById(
      state as StoreState,
      currentMember?.id
    )
  );
  const collectionRoles = React.useMemo(
    () =>
      currentMember?.roles?.filter(
        (role) => role.collection_id === collection.id
      ),
    [currentMember, collection.id]
  );

  // The toggle is disabled if the users collection role is set to 'none'
  const hasCollectionRole = React.useMemo(
    () =>
      !!collectionRoles?.filter(
        (role) =>
          role.role_type.toLowerCase() !== CollectionRole.host.toLowerCase()
      ).length,
    [collectionRoles]
  );
  // A Manager user always has upload permissions, even when host role is not present
  const isManager = React.useMemo(
    () =>
      isAdminOrOwner ||
      !!collectionRoles?.filter(
        (role) =>
          role.role_type.toLowerCase() === CollectionRole.manager.toLowerCase()
      ).length,
    [collectionRoles, isAdminOrOwner]
  );
  // A non-manager user has upload permissions only when host role is present
  const isHost = React.useMemo(
    () =>
      isManager ||
      (!!collectionRoles?.filter(
        (role) =>
          role.role_type.toLowerCase() === CollectionRole.host.toLowerCase()
      ).length &&
        hasCollectionRole),
    [isManager, collectionRoles, hasCollectionRole]
  );

  React.useEffect(() => {
    if (transaction) {
      const matchingTransaction = transactions.filter(
        (item) => item.id === transaction.id
      );
      if (!matchingTransaction.length) {
        setTransaction(undefined);
      } else {
        setTransaction(matchingTransaction[0]);
      }
    }
  }, [transactions, transaction]);

  const onUploadToggle = () => {
    if (currentMember) {
      let transaction;
      if (isHost) {
        // Remove Host Role
        transaction = generateTransaction({
          type: 'update_user_collection_role',
          crud: CRUD.delete,
          data: {
            id: currentMember.id,
            roles: [
              {
                role_type: 'host',
                collection_id: collection.id,
              },
            ],
            collectionId: collection.id,
          },
        });
      } else {
        // Add Host Role
        transaction = generateTransaction({
          type: 'update_user_collection_role',
          crud: CRUD.post,
          data: {
            id: currentMember.id,
            roles: [
              {
                role_type: 'host',
                collection_id: collection.id,
              },
            ],
            collectionId: collection.id,
          },
        });
      }
      setTransaction(transaction);
      dispatch(userTransaction(transaction));
    }
  };

  const disabled = !iAmAdminOrOwner || isManager || !hasCollectionRole;

  return (
    <div className={cx('d-flex', styles.uploadConversations)}>
      <Toggle
        lg
        id={`upload_conversation_${collection.id}`}
        testid={`upload-conversation-toggle-${collection.id}`}
        handleChange={onUploadToggle}
        checked={isHost}
        disabled={disabled}
      />
      {!isManager && transaction && !transaction.error && (
        <span className={styles.icon}>
          <LoadingSpinner active size="md" />
        </span>
      )}
      {isManager && (
        <>
          <FontAwesomeIcon
            icon={['fas', 'lock']}
            size="lg"
            id={`upload-conversation-lock-${collection.id}`}
            data-testid={`upload-conversation-lock-${collection.id}`}
            className={styles.icon}
            aria-label={UPLOADTOOLTIP}
          />
          <Tooltip
            placement="bottom"
            hideArrow={true}
            isOpen={lockTooltip}
            innerClassName={cx(styles.tooltip)}
            target={`upload-conversation-lock-${collection.id}`}
            toggle={() => setLockTooltip((self) => !self)}
            data-testid={`tooltip-upload-conversation-lock-${collection.id}`}
          >
            {UPLOADTOOLTIP}
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default UploadConversationsToggle;
