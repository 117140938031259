export interface OrganizationMetadata {
  id: number;
  upload_cap: number;
  num_conversations: number;
  community_id?: number;
}

export enum OrganizationRole {
  owner = 'Owner',
  admin = 'Admin',
  sensemaker = 'Sensemaker',
  member = 'Member',
  host = 'Host',
}

// Do not change indices of roles - will affect which role a user is given
export enum CommunityRole {
  owner = 'Owner',
  admin = 'Admin',
  sensemaker = 'Sensemaker',
  host = 'Host',
  member = 'Member',
}

export enum CatalogRole {
  sensemaker = 'Sensemaker',
}
