import moment from 'moment-timezone';

import {
  Operation,
  Organization,
  Permission,
  Role,
  User,
} from 'src/types/auth';
import { FeatureFlags } from 'src/types/core';
import { CommunityMember } from 'src/types/forum';

/**
 * Has a given permission
 */
export function hasPermission(user: User, permission: Permission): boolean {
  return user.permissions.some((perm) => {
    let isMatch =
      perm.operation === permission.operation &&
      perm.permission_type === permission.permission_type;

    // if the collection Id is specified
    if (permission.collection_id != null) {
      isMatch = isMatch && perm.collection_id === permission.collection_id;
    }

    // if the organization Id is specified
    if (permission.organization_id != null) {
      isMatch = isMatch && perm.organization_id === permission.organization_id;
    }

    // if the community Id is specified
    if (permission.community_id != null) {
      isMatch = isMatch && perm.community_id === permission.community_id;
    }

    // if the catalog Id is specified
    if (permission.catalog_id != null) {
      isMatch = isMatch && perm.catalog_id === permission.catalog_id;
    }

    // if the forum Id is specified
    if (permission.forum_id != null) {
      isMatch = isMatch && perm.forum_id === permission.forum_id;
    }

    return isMatch;
  });
}

/**
 * Has all of the permissions
 */
export function hasAllPermissions(
  user: User,
  permissions: Permission[]
): boolean {
  return !!permissions.every((perm) => hasPermission(user, perm));
}

/**
 * Has at least one of the permissions
 */
export function hasAnyPermission(
  user: User,
  permissions: Permission[]
): boolean {
  return !!permissions.some((perm) => hasPermission(user, perm));
}

/**
 * Check if authorized depending on if there are any leaven user permissions at all
 */
export function isAuthorized(user: User): boolean {
  return !!(user && user.permissions.length);
}

/**
 * Checks if a user is a role. If the passed in role has a collection ID,
 * then make sure the collection ID matches as well. Otherwise, just
 * the role type
 */
export function hasRole(user: User, role: Role) {
  return user.roles.some((r) => {
    // if the collection ID is specified
    let isMatch = r.role_type === role.role_type;
    if (role.collection_id != null) {
      isMatch = isMatch && r.collection_id === role.collection_id;
    }
    if (role.organization_id != null) {
      isMatch = isMatch && r.organization_id === role.organization_id;
    }
    return isMatch;
  });
}

/**
 * Has at least one of the roles
 */
export function hasAnyRole(user: User, roles: Role[]): boolean {
  return !!roles.some((role) => hasRole(user, role));
}

/**
 * Determines whether or not a user is at least a given role, or at most a given role
 * Also accounts for a role's collection ID
 * @param user The user to check
 * @param targetRole The minimum or maximum role to check the user role against
 * @param mode Flag to switch between >= and <=
 */
export function isAtLeastOrAtMostRole(
  user: User,
  targetRole: Role,
  mode: 'atLeast' | 'atMost'
): boolean {
  // get all the user roles relevant to the target role collection ID
  // if there is no target role collection ID, don't filter by collection ID
  let relevant_roles: Role[] = user.roles;
  if (targetRole.collection_id !== null) {
    // leave in roles that aren't associated with a collection
    relevant_roles = relevant_roles.filter(
      (role) =>
        role.collection_id === role.collection_id ||
        role.collection_id === undefined
    );
  }

  const targetRoleIndex: number = roleOrder.indexOf(targetRole.role_type);

  // find the highest role this user has (lower index is better)
  const userMaxRoleIndex: number = relevant_roles
    .map((role) => roleOrder.indexOf(role.role_type))
    .reduce((previousRoleIndex: number, currentRoleIndex: number) => {
      return previousRoleIndex > currentRoleIndex
        ? currentRoleIndex
        : previousRoleIndex;
    }, roleOrder.length);

  return mode === 'atLeast'
    ? userMaxRoleIndex <= targetRoleIndex
    : userMaxRoleIndex >= targetRoleIndex;
}

/**
 * Checks if the user passed in is part of the passed in organization. If user has no organization or if organization_id passed in is undefined, returns false.
 */
export function hasOrganization(
  user: User,
  organization_id: number | undefined
) {
  if (organization_id === undefined || user.organizations === undefined) {
    return false;
  }
  return user.organizations.some((org) => org.id === organization_id);
}

/**
 * True if the current user is anonymous
 */
export function isAnonymous(user: User) {
  return user.roles.filter((role) => role.role_type === 'anonymous').length > 0;
}

/** Should be in sync with permissions.py in the backend */
const permissions = {
  staffFeatures: 'Staff Features',
  leavenLabs: 'Leaven Labs',
  conversations: 'Conversations',
  conversationMetadata: 'Conversation Metadata',
  conversationPrivacy: 'Conversation Privacy',
  conversationTranscript: 'Conversation Transcript',
  highlightPrivacy: 'Highlight Privacy',
  highlights: 'Highlights',
  featureHighlights: 'Feature Highlights',
  publicHighlights: 'Public Highlights',
  corticoCuratedHighlights: 'Cortico Curated Highlights',
  audioUpload: 'Audio Upload',
  draftConversations: 'Draft Conversations',
  communityConversations: 'Community Conversations',
  communityHighlights: 'Community Highlights',
  communityUsers: 'Community Users',
  collections: 'Collections',
  organizationUsers: 'Organization Users',
  userOrganizationRoles: 'User Organization Roles',
  userCollectionRoles: 'User Collection Roles',
  insights: 'Insights',
};

/**
 * Helper methods to get permissions
 */
export function getConversationMetadataPermission(
  operation: Operation,
  collection_id?: number
): Permission {
  return {
    operation,
    permission_type: permissions.conversationMetadata,
    collection_id,
  };
}

export function getConversationPrivacyPermission(
  operation: Operation,
  collection_id?: number
): Permission {
  return {
    operation,
    permission_type: permissions.conversationPrivacy,
    collection_id,
  };
}

export function getConversationTranscriptPermission(
  operation: Operation,
  collection_id?: number
): Permission {
  return {
    operation,
    permission_type: permissions.conversationTranscript,
    collection_id,
  };
}

export function getHighlightPrivacyPermission(
  operation: Operation,
  collection_id?: number
): Permission {
  return {
    operation,
    permission_type: permissions.highlightPrivacy,
    collection_id,
  };
}

export function getHighlightPermission(
  operation: Operation,
  collection_id?: number
): Permission {
  return {
    operation,
    permission_type: permissions.highlights,
    collection_id,
  };
}

export function getCommunityConversationPermission(
  operation: Operation
): Permission {
  return {
    operation,
    permission_type: permissions.communityConversations,
  };
}

export function getCommunityHighlightPermission(
  operation: Operation
): Permission {
  return {
    operation,
    permission_type: permissions.communityHighlights,
  };
}

export function getCommunityUsersPermission(
  operation: Operation,
  community_id?: number
): Permission {
  return {
    operation,
    permission_type: permissions.communityUsers,
    community_id,
  };
}

export function getFeatureHighlightPermission(
  operation: Operation,
  collection_id?: number
): Permission {
  return {
    operation,
    permission_type: permissions.featureHighlights,
    collection_id,
  };
}

export function getStaffFeaturesPermission(operation: Operation): Permission {
  return {
    operation,
    permission_type: permissions.staffFeatures,
  };
}

export function getPublicHighlightPermission(
  operation: Operation,
  collection_id?: number
): Permission {
  return {
    operation,
    permission_type: permissions.publicHighlights,
    collection_id,
  };
}

export function getCorticoCuratedHighlightPermission(
  operation: Operation,
  collection_id?: number
): Permission {
  return {
    operation,
    permission_type: permissions.corticoCuratedHighlights,
    collection_id,
  };
}

export function getAudioUploadPermission(
  operation: Operation,
  collection_id?: number
): Permission {
  return {
    operation,
    permission_type: permissions.audioUpload,
    collection_id,
  };
}

export function getDraftConversationPermission(
  operation: Operation,
  collection_id?: number
): Permission {
  return {
    operation,
    permission_type: permissions.draftConversations,
    collection_id,
  };
}

export function getPermissionToCreateHighlight(
  isConversationProtected: boolean | undefined,
  collectionId: number
): Permission {
  // if we have an undefined convo privacy level, send back the more restrictive permission
  // (something has gone wrong)
  const protectedPerm = getHighlightPermission('create', collectionId);
  if (isConversationProtected == null) {
    return protectedPerm;
  }

  // otherwise normal behavior
  return isConversationProtected
    ? protectedPerm
    : getCommunityHighlightPermission('create');
}

export function getCollectionsPermission({
  operation,
  collection_id,
  organization_id,
}: {
  operation: Operation;
  collection_id?: number;
  organization_id?: number;
}): Permission {
  return {
    operation,
    permission_type: permissions.collections,
    collection_id,
    organization_id,
  };
}

export function getOrganizationMembersPermission(
  operation: Operation,
  organization_id?: number
): Permission {
  return {
    operation,
    permission_type: permissions.organizationUsers,
    organization_id,
  };
}

/**
 * Helper methods to get roles
 */

export function getStaffRole(): Role {
  return { role_type: 'staff' };
}

// roles associated with collections
export function getViewerRole(collection_id?: number): Role {
  return { role_type: 'viewer', collection_id };
}

export function getHighlighterRole(collection_id?: number): Role {
  return { role_type: 'highlighter', collection_id };
}

export function getHostRole(collection_id?: number): Role {
  return { role_type: 'host', collection_id };
}

export function getCollaboratorRole(collection_id?: number): Role {
  return { role_type: 'collaborator', collection_id };
}

export function getPreConversationRole(): Role {
  return { role_type: 'pre-conversation user' };
}

export function getManagerRole(collection_id?: number): Role {
  return { role_type: 'manager', collection_id };
}

export function getOrgSensemakerRole(organization_id?: number): Role {
  return { role_type: 'Sensemaker', organization_id };
}

export function getOrgAdminRole(organization_id?: number): Role {
  return { role_type: 'Admin', organization_id };
}

export function getOrgOwnerRole(organization_id?: number): Role {
  return { role_type: 'Owner', organization_id };
}

export function getAnonymousRole(): Role {
  return { role_type: 'anonymous' };
}

export function getInsightsRoles(user: User): Role[] {
  return user.organizations
    .map(({ id }) => [
      getOrgOwnerRole(id),
      getOrgAdminRole(id),
      getOrgSensemakerRole(id),
    ])
    .flat();
}

// role priority (lower = higher priority)
export const roleOrder = [
  'staff',
  'Owner',
  'Admin',
  'manager',
  'collaborator',
  'host',
  'Sensemaker',
  'highlighter',
  'viewer',
  'pre-conversation user',
  'anonymous',
];

export const anonymousUser: User = {
  id: 0,
  email: 'anonymous@cortico.ai',
  first_name: 'anonymous',
  last_name: 'anonymous',
  profile_image: undefined,
  phone_number: '',
  date_joined: moment(),
  roles: [{ role_type: 'anonymous' }],
  is_community_member: false,
  permissions: [{ operation: 'read', permission_type: 'Public Highlight' }],
  organizations: [],
  preferences: undefined,
  language: 'en-US',
  collections: [],
  topics: [],
  is_minor: false,
  has_password: false,
  lvn_terms_agreed: undefined,
  last_access: undefined,
  last_login: undefined,
};

/**
 * A single purpose function that returns the organizations where a user has the update Collections, create Collections, or read Draft Conversation permissions.
 */
export function getAdminPageOrgs(user: User) {
  // get roles where the user should be able to view the admin page
  const relevantPermissions = user.permissions.filter((permission) => {
    const type = permission.permission_type;
    const operation = permission.operation;
    // check that permissions are the correct type
    return (
      (type === permissions.collections && operation === 'update') ||
      (type === permissions.collections && operation === 'create') ||
      (type === permissions.draftConversations && operation === 'read') ||
      (type === permissions.organizationUsers && operation === 'create') ||
      (type === permissions.organizationUsers && operation === 'update') ||
      (type === permissions.organizationUsers && operation === 'delete')
    );
  });

  const orgs = relevantPermissions
    .map((permission) => {
      // return org from permissions where permission has organization_id
      if (permission.organization_id != null) {
        return user.organizations.find(
          (org) => org.id === permission.organization_id
        );
      }
      // return org from permissions where permission has collection_id
      if (permission.collection_id != null) {
        const collection = user.collections.find(
          (c) => c.id === permission.collection_id
        );
        if (collection) {
          return user.organizations.find(
            (org) => org.id === collection.organization_id
          );
        }
      }
      // return undefined where permission has no collection_id or organization_id
      return undefined;
    })
    // We want to remove undefined values here but typescript doesn't follow
    // we do this to make the types flow better so that ts can automatically figure it out
    // https://www.benmvp.com/blog/filtering-undefined-elements-from-array-typescript/
    .filter((org): org is Organization => !!org);

  // remove duplicates before returning
  return Array.from(new Set(orgs));
}

/**
 * Sorts users by last name, first name, then email
 * @param a User
 * @param b User
 * @returns
 */
export const sortUsers = (
  a: User | CommunityMember,
  b: User | CommunityMember
) => {
  let comp = 0;
  if (a.last_name && b.last_name) {
    comp = a.last_name.localeCompare(b.last_name);
  }
  if (comp !== 0) {
    return comp;
  }
  if (a.first_name && b.first_name) {
    comp = a.first_name.localeCompare(b.first_name);
  }
  if (comp !== 0) {
    return comp;
  }
  return a.email.localeCompare(b.email);
};

export const getFlag = (user: User, flag: FeatureFlags) => {
  return user.flags ? user.flags[flag] : false;
};
