import * as React from 'react';
import { connect } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useCatalogPageContext } from 'src/components/Insights/Catalog/CatalogPageProvider';
import { selectors as catalogSelectors } from 'src/redux/catalog/catalog-selectors';
import { LoadingMode } from 'src/redux/catalog/catalog-slice';
import { StoreState } from 'src/redux/store';
import {
  AbstractCode,
  Code,
  Visibility,
  VisibilityMap,
} from 'src/types/insights';
import CodeChip from '../Common/CodeChip';
import { calculateVisibility } from '../utils/code';

interface BaseProps<CodeType extends AbstractCode> {
  additionalIconButtons?: JSX.Element;
  codes: CodeType[];
  filter: string;
  onClickAdd: () => void;
  /**
   * Since TypeScript uses contravariant function parameter types, we're not able to declare openMenu as an anonymous function.
   * To sidestep this, we're using traditional function notation.
   * See https://sammart.in/post/2021-07-25-typescript-function-type-parameter-contravariance/#:~:text=Oooh%20the%20contravariance%20of%20Typescript's,a%20subtype%20of%20the%20parameter
   *
   * @param code Code or Demographic
   * @param element
   */
  openMenu(code: CodeType, element: Element): void;
  resetVisibility: () => void;
  selectCode: (id: number, selected: boolean) => void;
  selectedCodes?: CodeType['id'][];
  selecting: boolean;
  setFiltersVisibility: (id: number, visibility: boolean | undefined) => void;
  setVisibility: (id: number, visibility: boolean | undefined) => void;
  title: string;
  visibility?: VisibilityMap;
}

interface StateProps {
  loadingMode: LoadingMode;
}

const mapStateToProps = (state: StoreState): StateProps => ({
  loadingMode: catalogSelectors.getLoadingMode(state),
});

//TODO: delete me after insights restyle feature flag
const CodeAccordion = <CodeType extends AbstractCode>(
  props: BaseProps<CodeType> & StateProps
) => {
  const {
    codes,
    filter,
    loadingMode,
    onClickAdd,
    openMenu,
    resetVisibility,
    selectCode,
    selectedCodes,
    selecting,
    setFiltersVisibility,
    setVisibility,
    title,
    visibility,
  } = props;
  const { selectedEntry, t } = useCatalogPageContext();

  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickAdd = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onClickAdd();
  };

  const handleClickReset = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    resetVisibility();
  };

  const hasCode = (codeId: Code['id']) => {
    if (selectedCodes) return selectedCodes.includes(codeId);
    return selectedEntry?.codings?.some((c) => c.code_id === codeId) ?? false;
  };

  return (
    <>
      <ListItemButton dense disableGutters divider onClick={handleClick}>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        <ListItemText primary={title} />
        {visibility && Object.keys(visibility).length > 0 && (
          <Tooltip arrow title={t('insights.clear_filters')}>
            <IconButton onClick={handleClickReset} sx={{ py: '4px' }}>
              <ClearAllIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        {props.additionalIconButtons}
        <Tooltip
          arrow
          placement="left"
          title={t('insights.add_new_value', { value: title.toLowerCase() })}
        >
          <IconButton onClick={handleClickAdd} sx={{ py: '4px' }}>
            <AddIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List data-testid={`CodeAccordion-${title}`}>
          {codes.map((code) => {
            const indent = 1 + 3 * ((code.parentage || [1]).length - 1);
            const codeVisibility: Visibility = visibility
              ? calculateVisibility(code, visibility)
              : undefined;
            return (
              code.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 && (
                <ListItem disableGutters key={code.id} sx={{ py: 0 }}>
                  <ListItemText sx={{ pl: indent, py: 0, mr: '8px' }}>
                    <Tooltip
                      arrow
                      placement="left"
                      title={t('insights.toggle_coding')}
                    >
                      <Checkbox
                        checked={hasCode(code.id)}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          selectCode(code.id, event.target.checked);
                        }}
                        size="small"
                        sx={{
                          py: 0,
                          pl: 0,
                          pr: 0.5,
                          opacity: selecting ? 1 : 0,
                        }}
                      />
                    </Tooltip>
                    <CodeChip code={code} />
                  </ListItemText>
                  {(codeVisibility === 'visible' ||
                    codeVisibility === 'invisible') && (
                    <Tooltip
                      arrow
                      placement="left"
                      title={t('insights.remove_visibility_filter')}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <IconButton
                          onClick={() => {
                            loadingMode === 'fast'
                              ? setFiltersVisibility(code.id, undefined)
                              : setVisibility(code.id, undefined);
                          }}
                          sx={{ py: 0 }}
                        >
                          {codeVisibility === 'visible' ? (
                            <VisibilityIcon fontSize="small" />
                          ) : (
                            <VisibilityOffIcon fontSize="small" />
                          )}
                        </IconButton>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {(codeVisibility === 'implicitly-visible' ||
                    codeVisibility === 'implicitly-invisible') && (
                    <ListItemIcon sx={{ minWidth: 0 }}>
                      <IconButton disabled sx={{ py: 0 }}>
                        {codeVisibility === 'implicitly-visible' ? (
                          <VisibilityIcon fontSize="small" />
                        ) : (
                          <VisibilityOffIcon fontSize="small" />
                        )}
                      </IconButton>
                    </ListItemIcon>
                  )}
                  <Tooltip
                    arrow
                    placement="left"
                    title={t('insights.open_menu')}
                  >
                    <ListItemIcon sx={{ minWidth: 0 }}>
                      <IconButton
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          openMenu(code, event.currentTarget);
                        }}
                        sx={{ py: 0 }}
                      >
                        <MenuIcon fontSize="small" />
                      </IconButton>
                    </ListItemIcon>
                  </Tooltip>
                </ListItem>
              )
            );
          })}
          {codes.length === 0 && (
            <ListItem disableGutters disabled sx={{ py: 0 }}>
              <ListItemText sx={{ pl: 1, py: 0, my: 0 }}>
                <Typography variant="body2">
                  {t('insights.empty_codes')}
                </Typography>
              </ListItemText>
            </ListItem>
          )}
        </List>
      </Collapse>
    </>
  );
};

CodeAccordion.displayName = 'CodeAccordion';

export default connect(mapStateToProps)(CodeAccordion);
