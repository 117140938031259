import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavItem,
  UncontrolledDropdown,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { NumberParam, useQueryParams } from 'use-query-params';

import Color from 'src/assets/_util.scss';
import Button from 'src/components/core/Button/Button';
import NotificationBell from 'src/components/NotificationBell/NotificationBell';
import Restrict from 'src/components/Restrict/Restrict';
import authSelectors from 'src/redux/auth/auth-selectors';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import { getActiveCollection } from 'src/util/collections';
import { redirectUserToConversations } from 'src/util/urls';
import {
  getAudioUploadPermission,
  getDraftConversationPermission,
  getInsightsRoles,
  getPreConversationRole,
  getStaffFeaturesPermission,
  hasPermission,
  hasRole,
} from 'src/util/user';
import { canAccessAdminPage } from '../AdminPage/AdminPage';
import ForaLogo from '../ForaLogo/ForaLogo';

import styles from './MainNav.module.scss';

interface BaseProps {
  isAuthenticated: boolean;
  userEmail: string | undefined;
  onLogOut(evt: React.SyntheticEvent<any>): any;
}

interface StateProps {
  user: User;
}

type Props = BaseProps & StateProps;

const mapStateToProps = (state: StoreState): StateProps => ({
  user: authSelectors.getUser(state),
});

// generate link to values with collection id
const generateTo = (
  path: string,
  activeCollection: User['collections'][0] | undefined
) => {
  if (activeCollection != null) {
    return `${path}?c=${activeCollection.id}`;
  }

  return path;
};

const NavToggle: React.FC<{
  isOpen: boolean;
  onToggle: () => void;
}> = ({ isOpen, onToggle }) => {
  const width = 34;
  const height = 34;
  const line1 = isOpen ? [12, 0, 12, 0] : [0, 0, 24, 0]; //[4, 16, 20, 0] : [0, 0, 24, 0];
  const line2 = isOpen ? [4, 0, 20, 16] : [0, 8, 24, 8];
  const line3 = isOpen ? [4, 16, 20, 0] : [0, 16, 24, 16];

  const animProps = useSpring({
    l1x1: line1[0],
    l1y1: line1[1],
    l1x2: line1[2],
    l1y2: line1[3],
    l1Opacity: isOpen ? 0 : 1,
    l2x1: line2[0],
    l2y1: line2[1],
    l2x2: line2[2],
    l2y2: line2[3],
    l3x1: line3[0],
    l3y1: line3[1],
    l3x2: line3[2],
    l3y2: line3[3],
  });

  return (
    <svg
      width={width}
      height={height}
      className={cx('navbar-toggler', styles.navToggler)}
      onClick={onToggle}
      onKeyPress={(e) => e.key === 'Enter' && onToggle()}
      data-testid="mobile-account-dropdown"
      tabIndex={0}
    >
      <g transform="translate(5, 8)">
        <animated.line
          x1={animProps.l1x1}
          y1={animProps.l1y1}
          x2={animProps.l1x2}
          y2={animProps.l1y2}
          style={{ opacity: animProps.l1Opacity }}
        />
        <animated.line
          x1={animProps.l2x1}
          y1={animProps.l2y1}
          x2={animProps.l2x2}
          y2={animProps.l2y2}
        />
        <animated.line
          x1={animProps.l3x1}
          y1={animProps.l3y1}
          x2={animProps.l3x2}
          y2={animProps.l3y2}
        />
      </g>
    </svg>
  );
};

const AccountDropdown: React.FC<
  {
    className?: string;
  } & Pick<Props, 'onLogOut' | 'userEmail'>
> = ({ className, onLogOut, userEmail }) => {
  const { t } = useTranslation();
  const avatarStr = userEmail ? userEmail[0].toUpperCase() : ' ';

  return (
    <UncontrolledDropdown className={cx(styles.accountDropdown, className)}>
      <DropdownToggle
        color={''} /* needed to prevent bootstrap btn-xxx styling */
        className={cx(styles.userAvatar, styles.btnNavControl)}
        data-testid="account-dropdown"
      >
        {avatarStr}
      </DropdownToggle>
      <DropdownMenu className={cx(styles.accountDropdownMenu)}>
        <DropdownItem header className="small-header">
          {userEmail}
        </DropdownItem>
        <DropdownItem tag={Link} to="/settings">
          {t('main_nav.account_settings')}
        </DropdownItem>
        <DropdownItem divider />
        <Restrict type="custom" customCheck={canAccessAdminPage}>
          <DropdownItem tag={Link} to="/admin" data-testid="admin-link">
            {t('main_nav.account_admin')}
          </DropdownItem>
        </Restrict>
        <DropdownItem tag={Link} to="/legal">
          {t('main_nav.account_legal')}
        </DropdownItem>
        <DropdownItem
          tag={Link}
          to={{ pathname: 'https://help.fora.io/hc/en-us' }}
          target="_blank"
          className="align-middle"
          data-testid="help-link"
        >
          {t('main_nav.account_help')}
          <FontAwesomeIcon
            icon={['far', 'external-link-alt']}
            className="float-end mt-1"
          />
        </DropdownItem>
        <DropdownItem onClick={onLogOut}>
          {t('main_nav.account_logout')}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const MobileAccountDropdown: React.FC<
  {
    className?: string;
    handleMenuItemClick: () => void;
  } & Pick<Props, 'onLogOut' | 'userEmail'>
> = ({ className, onLogOut, userEmail, handleMenuItemClick }) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <div className={cx('small-header', styles.navHeader)}>{userEmail}</div>
      <Restrict permission={getDraftConversationPermission('read')}>
        <NavItem>
          <NavLink
            exact
            className={styles.navLink}
            to="/admin"
            onClick={handleMenuItemClick}
            data-testid="mobile-admin-link"
          >
            {t('main_nav.account_admin')}
          </NavLink>
        </NavItem>
      </Restrict>
      <NavItem>
        <NavLink
          exact
          className={styles.navLink}
          to="/settings"
          onClick={handleMenuItemClick}
        >
          {t('main_nav.account_settings')}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          exact
          className={styles.navLink}
          to="/legal"
          onClick={handleMenuItemClick}
        >
          {t('main_nav.account_legal')}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          exact
          className={styles.navLink}
          to={{ pathname: 'https://help.fora.io/hc/en-us' }}
          target="_blank"
          onClick={handleMenuItemClick}
          data-testid="mobile-help-link"
        >
          {t('main_nav.account_help')}
          <FontAwesomeIcon
            icon={['far', 'external-link-alt']}
            className="float-end mt-1"
          />
        </NavLink>
      </NavItem>
      <NavItem>
        <Button
          color="link"
          className={`${styles.navLink} w-100 text-start`}
          onClick={onLogOut}
        >
          {t('main_nav.account_logout')}
        </Button>
      </NavItem>
    </div>
  );
};

const MainNav = ({ isAuthenticated, onLogOut, user, userEmail }: Props) => {
  const { t } = useTranslation();
  const [query] = useQueryParams({ c: NumberParam });

  const handleSignIn = () => {
    window.location.href = `/login/?next=${redirectUserToConversations(
      window.location.pathname
    )}`;
  };

  // get the active collection based on what we have last seen.
  const activeCollection = getActiveCollection(query.c, user);

  const [expanded, setExpanded] = React.useState(false);
  const toggleExpand = () => setExpanded(!expanded);
  const collapseNav = () => {
    if (expanded) {
      setExpanded(false);
    }
  };

  // add listeners to close the nav if the user clicks outside of it
  const navRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    const clickOutsideToClose: any = (
      evt: React.SyntheticEvent<MouseEvent>
    ) => {
      const navContainer = navRef.current;

      // if the click occurred outside the nav
      if (
        navContainer != null &&
        evt.target !== navContainer &&
        !navContainer.contains(evt.target as any)
      ) {
        setExpanded(false);
      }
    };

    // if the nav is expanded, add the listener to the document
    if (expanded) {
      document.addEventListener('click', clickOutsideToClose, true);
    }
    return () => {
      // if the nav was expanded, during cleanup remove the listener
      if (expanded) {
        document.removeEventListener('click', clickOutsideToClose, true);
      }
    };
  }, [expanded, setExpanded]);

  // don't show the home button to pre-convo user, who see scheduled conversations instead
  const showHomeNav = !hasRole(user, getPreConversationRole());

  // only use nav links with collection query param ("c=") from homepage
  const linkWithCollection =
    window.location.pathname === '/' &&
    activeCollection &&
    !activeCollection.is_all_collection_option;
  const conversationsPath = linkWithCollection
    ? generateTo('/conversations', activeCollection)
    : '/conversations';
  const highlightsPath = linkWithCollection
    ? generateTo('/highlights', activeCollection)
    : '/highlights';

  const activeStyle = {
    background: Color.foraPurpleDark,
    cursor: 'default',
  };

  return (
    <div className={styles.mainNav} data-testid="main-nav" ref={navRef}>
      <Navbar role="navigation" className={styles.navBody} expand="md">
        <div>
          <Link
            to="/"
            className={cx(styles.navLogoLink, styles.navSection)}
            aria-hidden
            tabIndex={-1}
          >
            <ForaLogo />
          </Link>
          {isAuthenticated && (
            <NavToggle isOpen={expanded} onToggle={toggleExpand} />
          )}
        </div>
        <Collapse isOpen={expanded} navbar>
          <Nav className={cx(styles.navLinks, styles.navSection)} navbar>
            {isAuthenticated && (
              <React.Fragment>
                {showHomeNav && (
                  <NavItem>
                    <NavLink
                      exact
                      className={styles.navLink}
                      to={generateTo('/', activeCollection)}
                      data-testid="home-nav-link"
                      onClick={collapseNav}
                      activeStyle={activeStyle}
                    >
                      {t('main_nav.home')}
                    </NavLink>
                  </NavItem>
                )}
                <NavItem>
                  <NavLink
                    className={styles.navLink}
                    to={highlightsPath}
                    data-testid="highlights-nav-link"
                    onClick={collapseNav}
                    activeStyle={activeStyle}
                  >
                    {t('main_nav.highlights')}
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={styles.navLink}
                    to={conversationsPath}
                    data-testid="conversations-nav-link"
                    onClick={collapseNav}
                    activeStyle={activeStyle}
                  >
                    {t('main_nav.conversations')}
                  </NavLink>
                </NavItem>
                <Restrict anyRole={getInsightsRoles(user)}>
                  <NavItem>
                    <NavLink
                      className={styles.navLink}
                      to={'/insights'}
                      data-testid="insights-nav-link"
                      onClick={collapseNav}
                      activeStyle={activeStyle}
                    >
                      Insights
                    </NavLink>
                  </NavItem>
                </Restrict>
                <Restrict
                  anyPermission={[
                    getAudioUploadPermission('create'),
                    getStaffFeaturesPermission('read'),
                  ]}
                >
                  <UncontrolledDropdown
                    nav
                    inNavbar
                    data-testid="host-nav-dropdown"
                  >
                    <DropdownToggle
                      caret
                      nav
                      className={styles.navLink}
                      data-testid="host-dropdown"
                    >
                      {t('main_nav.hosts')}
                    </DropdownToggle>
                    <DropdownMenu className={styles.hostDropdownMenu}>
                      <DropdownItem
                        tag={'a'}
                        href="https://help.fora.io/hc/en-us/sections/1500002123141-Facilitator-Resources"
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={collapseNav}
                        data-testid="host-resources"
                      >
                        {t('main_nav.resources')}
                      </DropdownItem>
                      {hasPermission(
                        user,
                        getAudioUploadPermission('create')
                      ) && (
                        <DropdownItem
                          tag={Link}
                          to={generateTo(
                            '/conversation-upload',
                            activeCollection
                          )}
                          onClick={collapseNav}
                          data-testid="upload-item"
                        >
                          {t('main_nav.upload')}
                        </DropdownItem>
                      )}
                      {hasPermission(
                        user,
                        getAudioUploadPermission('create')
                      ) && (
                        <DropdownItem
                          tag={Link}
                          to={generateTo(
                            '/conversation-record',
                            activeCollection
                          )}
                          onClick={collapseNav}
                          data-testid="record-item"
                        >
                          {t('main_nav.record')}
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Restrict>

                <MobileAccountDropdown
                  onLogOut={onLogOut}
                  userEmail={userEmail}
                  handleMenuItemClick={collapseNav}
                  className="d-block d-md-none"
                />
              </React.Fragment>
            )}
          </Nav>
        </Collapse>
        <div className={cx(styles.rightLinks, styles.navSection)}>
          {isAuthenticated ? (
            <div className="d-none d-md-flex">
              <NotificationBell className="me-2" />
              <AccountDropdown onLogOut={onLogOut} userEmail={userEmail} />
            </div>
          ) : (
            <Button onClick={handleSignIn} className={`${styles.navLink} ms-2`}>
              {t('main_nav.sign_in')}
            </Button>
          )}
        </div>
      </Navbar>
    </div>
  );
};

export default connect(mapStateToProps)(MainNav);
