import { useTranslation } from 'react-i18next';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import {
  ConsumeEvents,
  Table,
  TableProps,
} from 'src/components/core/Table/Table';
import { CommunityMember } from 'src/types/forum';
import NameColumnContent from '../../Common/TableColumnContent/NameColumnContent';
import CommunityRoleDropdown from '../../OrganizationMembers/PillDropdowns/CommunityRoleDropdown';
import CommunityRoleTooltip from '../../OrganizationMembers/Tooltips/CommunityRoleTooltip';
import AddCommunityMemberButton from './AddCommunityMemberButton';

const RoleContent = ({ val: member }: { val: CommunityMember }) => {
  return <CommunityRoleDropdown member={member} />;
};

const ActionContent = ({ val: member }: { val: CommunityMember }) => {
  return (
    <ConsumeEvents>
      <AddCommunityMemberButton member={member} />
    </ConsumeEvents>
  );
};

const CommunityRoleColumnLabel = () => {
  const { t } = useTranslation();
  const label = t('admin.add_community_member_table_role_label');
  return (
    <div className="d-flex">
      {label}
      <CommunityRoleTooltip width="300px" />
    </div>
  );
};

const AddCommunityMemberTable = ({ values }: { values: CommunityMember[] }) => {
  const { t } = useTranslation();
  const [, setQuery] = useQueryParams({
    userId: NumberParam,
    page: StringParam,
  });

  const goToUser = (communityMember: CommunityMember) => {
    setQuery({ userId: communityMember.id, page: 'view' }, 'pushIn');
  };

  const tableProps: TableProps<CommunityMember> = {
    values,
    valToKey: (val) => val.id,
    onRowClick: goToUser,
    testId: 'add-community-member-table',
    columns: [
      {
        id: 'name',
        headerLabel: t('admin.add_community_member_table_name_label'),
        content: NameColumnContent,
      },
      {
        id: 'role',
        widthFraction: 4,
        headerLabel: CommunityRoleColumnLabel,
        content: RoleContent,
      },
      {
        id: 'action',
        alignRight: true,
        widthFraction: 2,
        headerLabel: '',
        content: ActionContent,
      },
    ],
  };

  return <Table {...tableProps} />;
};

export default AddCommunityMemberTable;
