import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import { VSpacer } from 'src/components/core/Spacer';
import { HStack, VStack } from 'src/components/core/Stack';
import { FilterField } from 'src/components/Insights/Catalog/FilterField';
import { HighlightCardModal } from 'src/components/Insights/Catalog/HighlightCardModal';
import { useHighlightsPageContext } from 'src/components/Insights/Catalog/HighlightsPageProvider';
import { HighlightsPageToolbar } from 'src/components/Insights/Catalog/HighlightsPageToolbar';
import { useFlag } from 'src/Providers/FlagProvider';
import { selectors as catalogSelectors } from 'src/redux/catalog/catalog-selectors';
import { selectEntry } from 'src/redux/catalog/catalog-slice';
import { selectors as filtersSelectors } from 'src/redux/catalog-filters/catalog-filters-selectors';
import { Entry } from 'src/types/insights';
import FilterableHighlightGrid from '../Filters/FilterableHighlightGrid';
import HighlightCard from './HighlightCard';

export default function HighlightsPage() {
  const { selectedEntry, entries, showModal, hideModal, dispatch } =
    useHighlightsPageContext();

  useEffect(() => {
    if (!selectedEntry) return;

    hideModal(); // hides previous highlight modal when using carousel nav
    showModal(<HighlightCardModal />, {
      onClose: () => dispatch(selectEntry()),
      withCodebook: true,
      containerStyle: {
        background: 'transparent',
        maxHeight: 'unset',
        height: '100%',
        pointerEvents: 'none',
        width: '70%',
      },
    });
  }, [selectedEntry]);

  useEffect(() => {
    if (selectedEntry && !entries.some((e) => e.id === selectedEntry.id)) {
      // when checking/uncecking a code removes the entry from the filtered list of entries,
      // we gotta hide the entry to prevent a crash (cuz it's no longer accessible)
      hideModal();
      dispatch(selectEntry(undefined));
    }
  }, [entries, selectedEntry]);

  const improvedFiltering = useFlag('highlight_filtering');

  return (
    <VStack margin="0 auto" flexGrow={1}>
      <VStack
        maxWidth={'1280px'}
        width={'100%'}
        alignSelf={'center'}
        padding={'0 !important'}
      >
        {!improvedFiltering && <Filters />}
        <HighlightsPageToolbar />
      </VStack>
      {!improvedFiltering && <HighlightGrid />}
      {improvedFiltering && <FilterableHighlightGrid />}
    </VStack>
  );
}

// SUBCOMPONENTS

function Filters() {
  const { t } = useTranslation();
  return (
    <HStack flexWrap="wrap" gap="16px" padding={'2rem 2rem 0'}>
      <FilterField
        label={t('insights.filter_conversations')}
        visibilityCategory="conversations"
        optionLabelField="title"
        optionsSelector={catalogSelectors.getConversations}
        visibilitySelector={filtersSelectors.getConversationVisibilityMap}
      />
      <FilterField
        label={t('insights.filter_participants')}
        visibilityCategory="participants"
        optionLabelField="name"
        optionsSelector={catalogSelectors.getParticipants}
        visibilitySelector={filtersSelectors.getParticipantVisibilityMap}
      />
      <FilterField
        label={t('insights.filter_highlighters')}
        visibilityCategory="highlighters"
        optionLabelField="user_name"
        optionsSelector={catalogSelectors.getAuthors}
        visibilitySelector={filtersSelectors.getHighlighterVisibilityMap}
      />
    </HStack>
  );
}

function HighlightGrid() {
  const {
    visibleHighlights,
    selectedHighlightIds,
    setSelectedHighlightIds,
    dispatch,
  } = useHighlightsPageContext();

  const onClick = useCallback(
    (entry: Entry) => dispatch(selectEntry(entry.id)),
    []
  );

  const onChangeCheck = useCallback(
    (checked: boolean, highlight: Entry) => {
      const nowSelectedHighlights = new Set(selectedHighlightIds);
      nowSelectedHighlights[checked ? 'add' : 'delete'](highlight.id);
      setSelectedHighlightIds(nowSelectedHighlights);
    },
    [selectedHighlightIds]
  );

  return (
    <Grid
      container
      item
      spacing={3}
      xs={12}
      style={{
        maxWidth: '1280px',
        flexBasis: 'unset',
        margin: '0 auto',
        paddingTop: '.5rem',
        overflowY: 'auto',
        flexGrow: 1,
        boxSizing: 'border-box',
        justifyContent: 'center',
      }}
    >
      {visibleHighlights?.map((entry) => (
        <Grid
          item
          alignItems="stretch"
          lg={6}
          key={entry.id}
          sx={{
            padding: '0 24px 24px 24px !important',
            maxWidth: { sm: '650px' },
          }}
        >
          <HighlightCard
            entry={entry}
            onClick={() => onClick(entry)}
            onChangeCheck={onChangeCheck}
            checked={selectedHighlightIds.has(entry.id)}
          />
        </Grid>
      ))}
      <VSpacer height="24px" />
    </Grid>
  );
}
