import { useTranslation } from 'react-i18next';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import {
  ConsumeEvents,
  Table,
  TableProps,
} from 'src/components/core/Table/Table';
import { User } from 'src/types/auth';
import NameColumnContent from '../../Common/TableColumnContent/NameColumnContent';
import RemoveMemberButton from '../../OrganizationMembers/RemoveMemberButton/RemoveMemberButton';
import UserStatus from '../../OrganizationMembers/UserStatus/UserStatus';
import OrganizationRoleDropdown from '../PillDropdowns/OrganizationRoleDropdown';
import OrganizationRoleTooltip from '../Tooltips/OrganizationRoleTooltip';

const RoleContent = ({ val: member }: { val: User }) => {
  return <OrganizationRoleDropdown member={member} />;
};

const StatusContent = ({ val: member }: { val: User }) => {
  return <UserStatus user={member} />;
};

const ActionContent = ({ val: member }: { val: User }) => {
  return (
    <ConsumeEvents>
      <RemoveMemberButton member={member} communityTable={false} />
    </ConsumeEvents>
  );
};

const OrganizationRoleColumnLabel = () => {
  const { t } = useTranslation();
  const label = t('admin.tab_team_member_list_column_2');
  return (
    <div className="d-flex">
      {label}
      <OrganizationRoleTooltip width="300px" />
    </div>
  );
};

const OrganizationMembersTable = ({ values }: { values: User[] }) => {
  const { t } = useTranslation();
  const [, setQuery] = useQueryParams({
    userId: NumberParam,
    page: StringParam,
  });

  const goToUser = (user: User) => {
    setQuery({ userId: user.id, page: 'view' }, 'pushIn');
  };

  const tableProps: TableProps<User> = {
    values,
    valToKey: (val) => val.id,
    onRowClick: goToUser,
    testId: 'organization-member-table',
    columns: [
      {
        id: 'name',
        headerLabel: t('admin.tab_team_member_list_column_1'),
        content: NameColumnContent,
        widthFraction: 4,
      },
      {
        id: 'role',
        widthFraction: 2,
        headerLabel: OrganizationRoleColumnLabel,
        content: RoleContent,
      },
      {
        id: 'status',
        widthFraction: 5,
        headerLabel: t('admin.tab_team_member_list_column_3'),
        content: StatusContent,
      },
      {
        id: 'action',
        alignRight: true,
        widthFraction: 1,
        headerLabel: '',
        content: ActionContent,
      },
    ],
  };

  return <Table {...tableProps} />;
};

export default OrganizationMembersTable;
