import { User } from 'src/types/auth';
import { CommunityMember } from 'src/types/forum';

export const ACTIVE_THRESHOLD = 30;

export const matchMember = (query: string, member: User | CommunityMember) => {
  // Join the queries to match on (note, first last position is important)
  return `${member.first_name} ${member.last_name} ${member.email}`
    .toLowerCase() // Make case insensitive
    .includes(query.toLowerCase());
};

export const avatarLabel = ({ email }: { email?: string } = {}) => {
  return email ? email[0].toUpperCase() : ' ';
};

export const fullName = ({
  first_name,
  last_name,
}: {
  first_name?: string;
  last_name?: string;
} = {}) => {
  return first_name ? `${first_name} ${last_name || ''}` : `${last_name || ''}`;
};
