import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'reactstrap';

import adminSelectors from 'src/redux/admin/admin-selectors';
import { userTransaction } from 'src/redux/admin/admin-slice';
import { generateTransaction } from 'src/redux/redux-helpers';
import { StoreState } from 'src/redux/store';
import { UserTransactions } from 'src/types/admin';
import { Role } from 'src/types/auth';
import { Collection, CollectionRole } from 'src/types/collection';
import { CRUD } from 'src/types/core';
import PillDropdown from './PillDropdown';

interface CollectionRoleDropdownProps {
  collection: Collection;
}

const CollectionRoleDropdown = ({
  collection,
}: CollectionRoleDropdownProps) => {
  const { t } = useTranslation();
  const COLLECTION_ROLE_TOOLTIP = t('admin.tab_team_collection_role_tooltip');
  const dispatch = useDispatch();
  const currentMember = useSelector(adminSelectors.getCurrentUser);
  const iAmAdminOrOwner = useSelector(adminSelectors.iAmAdminOrOwner);
  const isAdminOrOwner = useSelector(adminSelectors.isAdminOrOwner);
  const isSenseMaker = useSelector(adminSelectors.isOrganizationSenseMaker);
  const transactions = useSelector((state) =>
    adminSelectors.getUserTransactionsById(
      state as StoreState,
      currentMember?.id
    )
  );
  const [transaction, setTransaction] = React.useState<
    UserTransactions | undefined
  >();
  const [tooltip, setTooltip] = React.useState<boolean>(false);

  const roleFilter = (role: Role) => {
    return (
      role.collection_id === collection.id &&
      Object.values(CollectionRole)
        // remove host role and lowercase the rest
        .map((role) => (role === CollectionRole.host ? '' : role.toLowerCase()))
        .includes(role.role_type as CollectionRole)
    );
  };
  const collectionRole = isAdminOrOwner
    ? CollectionRole.manager
    : // TODO: this is currently making it default to highlighter no matter the collection roles you have
    // If we keep this we should add a tooltip and disable this dropdown if you're a sensemaker
    isSenseMaker
    ? CollectionRole.highlighter
    : currentMember?.roles.filter(roleFilter)[0]?.role_type || 'None';

  // check if the user has the host role directly
  const isAssignedHost =
    currentMember &&
    currentMember.roles.filter(
      (r) =>
        r.role_type.toLowerCase() === CollectionRole.host.toLowerCase() &&
        r.collection_id === collection.id
    ).length > 0;

  React.useEffect(() => {
    if (transaction) {
      const matchingTransaction = transactions.filter(
        (item) => item.id === transaction.id
      );
      if (!matchingTransaction.length) {
        setTransaction(undefined);
      } else {
        setTransaction(matchingTransaction[0]);
      }
    }
  }, [transactions, transaction]);

  const handleCollectionRoleChange = (collectionRole: string) => {
    if (currentMember) {
      let roles: Role[];

      // build the roles array
      if (collectionRole === 'None') {
        roles = [];
      } else {
        roles = [
          {
            role_type: collectionRole.toLowerCase(),
            collection_id: collection.id,
          },
        ];
      }
      // if the user is a host, they should retain that role, so make sure it's still in the array
      if (isAssignedHost) {
        roles.push({
          role_type: CollectionRole.host.toLowerCase(),
          collection_id: collection.id,
        });
      }
      const transaction = generateTransaction({
        type: 'update_user_collection_role',
        crud: CRUD.put,
        data: {
          id: currentMember.id,
          collectionId: collection.id,
          roles,
        },
      });

      setTransaction(transaction);
      dispatch(userTransaction(transaction));
    }
  };

  const disabled = isAdminOrOwner || !iAmAdminOrOwner;

  return (
    <>
      <PillDropdown
        dropdownItems={[
          {
            value: CollectionRole.manager,
            theme: 'lightGray',
          },
          {
            value: CollectionRole.collaborator,
            theme: 'lightGray',
          },
          {
            value: CollectionRole.highlighter,
            theme: 'lightGray',
          },
          {
            value: CollectionRole.viewer,
            theme: 'lightGray',
            hide: isSenseMaker,
          },
          {
            value: 'None',
            theme: 'lightGray',
            hide: isSenseMaker,
          },
        ]}
        selectedValue={collectionRole}
        onSelect={handleCollectionRoleChange}
        loading={!!transaction && !transaction.error}
        disable={disabled}
        id={`collection-role-${collection.id}`}
        aria-label={isAdminOrOwner ? COLLECTION_ROLE_TOOLTIP : ''}
        testId={`collection-role-${collection.id}`}
      />
      {isAdminOrOwner && (
        <Tooltip
          boundariesElement="window"
          placement="bottom"
          hideArrow={true}
          isOpen={tooltip}
          target={`collection-role-${collection.id}`}
          toggle={() => setTooltip((self) => !self)}
          data-testid={`tooltip-collection-role-${collection.id}`}
        >
          {COLLECTION_ROLE_TOOLTIP}
        </Tooltip>
      )}
    </>
  );
};

export default CollectionRoleDropdown;
