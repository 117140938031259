import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from 'src/components/core/Button/Button';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { StoreState } from 'src/redux/store';
import { CommunityMember } from 'src/types/forum';
import { OrganizationRole } from 'src/types/organization';
import { useUserTransaction } from '../../Common/Hooks/useUserTransaction';
import { fullName } from '../../Common/utils';

import styles from './AddCommunityMemberButton.module.scss';

interface AddCommunityMemberButtonProps {
  member: CommunityMember;
  useIcon?: boolean;
}

const AddCommunityMemberButton = ({
  member,
  useIcon,
}: AddCommunityMemberButtonProps) => {
  const { t } = useTranslation();

  const organizationId = useSelector(adminSelectors.getOrganizationId);

  const { startTransaction, transactionLoading } = useUserTransaction();

  const isOwner =
    useSelector((state) =>
      (state as StoreState).admin.users.filter(({ id }) => id === member.id)
    )[0]?.roles.filter(
      (role) =>
        role.organization_id === organizationId &&
        role.role_type.toLowerCase() === OrganizationRole.owner.toLowerCase()
    ).length ?? false;

  const onAddClicked = () => {
    const name = fullName(member);

    startTransaction({
      type: 'add_community_member',
      data: { id: member.id },
      toast: {
        onError: {
          content: (err) =>
            t('admin.add_community_member_error_toast', {
              name,
            }),
          options: {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: false,
          },
        },
        onSuccess: {
          content: t('admin.add_community_member_success_toast', {
            name,
          }),
          options: {
            position: toast.POSITION.BOTTOM_RIGHT,
          },
        },
      },
    });
  };

  const buttonText = t('common.add');

  const testId = `add-community-member-button-${member.id}`;

  //Return Logic
  if (isOwner) {
    return <></>;
  } else if (useIcon) {
    return (
      <Button
        outline
        className="shadow-none"
        icon={['fas', 'trash']}
        aria-label={'Add Community Member'}
        onClick={onAddClicked}
        data-testid={testId}
      />
    );
  } else {
    return (
      <Button
        className={styles.addButton}
        aria-label={'Add Community Member'}
        onClick={onAddClicked}
        data-testid={testId}
      >
        {transactionLoading ? <LoadingSpinner active size="sm" /> : buttonText}
      </Button>
    );
  }
};

export default AddCommunityMemberButton;
