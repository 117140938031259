import { useSelector } from 'react-redux';
import cx from 'classnames';

import { useUserTransaction } from 'src/components/AdminPage/Common/Hooks/useUserTransaction';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import Toggle from 'src/components/core/Toggle/Toggle';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { StoreState } from 'src/redux/store';
import { CRUD } from 'src/types/core';
import { Catalog } from 'src/types/insights';
import { CatalogRole } from 'src/types/organization';

import styles from './CatalogSenseMakerToggle.module.scss';

export type CatalogSenseMakerToggleProps = {
  catalog: Catalog;
};

const CatalogSenseMakerToggle = ({ catalog }: CatalogSenseMakerToggleProps) => {
  const iAmAdminOrOwner = useSelector(adminSelectors.iAmAdminOrOwner);
  const isCatalogSenseMaker = useSelector((state: StoreState) =>
    adminSelectors.isCatalogSenseMaker(state, catalog.id)
  );

  const { startTransaction, transactionLoading } = useUserTransaction();

  const currentMember = useSelector(adminSelectors.getCurrentUser);

  const onToggle = () => {
    if (currentMember) {
      startTransaction({
        type: 'update_user_catalog_role',
        crud: isCatalogSenseMaker ? CRUD.delete : CRUD.put,
        data: {
          id: currentMember.id,
          catalogId: catalog.id,
          organization_id: catalog.organization_id,
          roles: [
            {
              role_type: CatalogRole.sensemaker.toLowerCase(),
            },
          ],
        },
      });
    }
  };

  const disabled = !iAmAdminOrOwner;

  return (
    <div className={cx('d-flex', styles.catalogSenseMakerToggle)}>
      <Toggle
        lg
        id={`catalog_sensemaker_toggle_${catalog.id}`}
        testid={`catalog-sensemaker-toggle-${catalog.id}`}
        handleChange={onToggle}
        checked={isCatalogSenseMaker}
        disabled={disabled}
      />
      {transactionLoading && (
        <span className={styles.icon}>
          <LoadingSpinner active size="md" />
        </span>
      )}
    </div>
  );
};

export default CatalogSenseMakerToggle;
