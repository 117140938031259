import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import SearchableTable from 'src/components/AdminPage/Common/SearchableTable';
import Button from 'src/components/core/Button/Button';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { matchMember } from '../../Common/utils';
import OrganizationMembersTable from './OrganizationMembersTable';

const Header = () => {
  const { t } = useTranslation();
  const members = useSelector(adminSelectors.getUsers);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row">
        <span>{t('admin.tab_team_list_helper_1')}</span>
      </div>
      <span>
        {t('admin.tab_team_list_helper_2', {
          count: members.length,
        })}
      </span>
    </div>
  );
};

const AddOrganizationMemberAction = () => {
  const { t } = useTranslation();

  const [, setQuery] = useQueryParams({
    page: StringParam,
    userId: NumberParam,
  });

  const onAddButtonClicked = () => {
    setQuery({ page: 'add', userId: undefined }, 'pushIn');
  };

  return (
    <div style={{ margin: 'auto' }}>
      <Button
        type="submit"
        color="primary"
        className="ms-4"
        style={{ width: '200px', height: '40px' }}
        onClick={onAddButtonClicked}
        data-testid={`organization-member-list-add-button`}
      >
        {t('common.add')}
      </Button>
    </div>
  );
};

const OrganizationMembersList = () => {
  const members = useSelector(adminSelectors.getUsers);
  const isLoadingUsers = useSelector(adminSelectors.isLoadingUsers);

  return (
    <SearchableTable
      values={members}
      queryFilter={matchMember}
      header={Header}
      table={OrganizationMembersTable}
      action={AddOrganizationMemberAction}
      loading={isLoadingUsers}
    />
  );
};

export default OrganizationMembersList;
