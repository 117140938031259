import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import SearchableTable from 'src/components/AdminPage/Common/SearchableTable';
import Button from 'src/components/core/Button/Button';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { setCommunity } from 'src/redux/admin/admin-slice';
import authSelectors from 'src/redux/auth/auth-selectors';
import { canAccessAdminCommunity } from '../../AdminPage';
import { matchMember } from '../../Common/utils';
import CommunityDropdown from '../../CommunityDropdown/CommunityDropdown';
import { TeamPageName } from '../../MemberListPage/MemberListPage';
import CommunityMembersTable from './CommunityMembersTable';

const Header = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentUser = useSelector(authSelectors.getUser);
  const activeCommunity = useSelector(adminSelectors.getActiveCommunity);
  const communities = useSelector(adminSelectors.getCommunities);
  const members = useSelector(adminSelectors.getCommunityMembers);

  const handleCommunityChange = (oid: number) => {
    const newOrg = communities.find((c) => c?.id === oid);
    if (newOrg) {
      dispatch(setCommunity(newOrg));
    }
  };

  const communitiesICanAccess = useMemo(() => {
    return communities.filter((c) =>
      canAccessAdminCommunity(c.id, currentUser)
    );
  }, [communities, currentUser]);

  return (
    <div className="d-flex flex-column">
      <CommunityDropdown
        communities={communitiesICanAccess}
        activeCommunity={activeCommunity}
        onChange={handleCommunityChange}
        headerTag="h1"
        className="mb-3"
        headerClassname="h2"
      />
      <div className="d-flex flex-row">
        <span>{t('admin.tab_community_list_helper_1')}</span>
      </div>
      <span>
        {t('admin.tab_community_list_helper_2', {
          count: members.length,
        })}
      </span>
    </div>
  );
};

const AddCommunityMemberAction = () => {
  const { t } = useTranslation();

  const [, setQuery] = useQueryParams({
    page: StringParam,
    userId: NumberParam,
  });

  const onPageChange = (page: TeamPageName) => {
    setQuery({ page, userId: undefined }, 'pushIn');
  };

  return (
    <div style={{ margin: 'auto' }}>
      <Button
        type="submit"
        color="primary"
        className="ms-4"
        style={{ width: '200px', height: '40px' }}
        onClick={() => onPageChange('add')}
        data-testid={`team-add-button`}
      >
        {t('common.add')}
      </Button>
    </div>
  );
};

const CommunityMembersList = () => {
  const members = useSelector(adminSelectors.getCommunityMembers);
  const isLoadingUsers = useSelector(adminSelectors.isLoadingUsers);

  return (
    <SearchableTable
      values={members}
      queryFilter={matchMember}
      header={Header}
      table={CommunityMembersTable}
      action={AddCommunityMemberAction}
      loading={isLoadingUsers}
    />
  );
};

export default CommunityMembersList;
