import React from 'react';
import cx from 'classnames';

import styles from './Toggle.module.scss';

interface Props {
  handleChange: (e: React.ChangeEvent<any>) => void;
  checked: boolean;
  id: string;
  testid: string;
  label?: string;
  disabled?: boolean;
  title?: string;
  lg?: boolean;
}
const Toggle = ({
  handleChange,
  checked,
  label,
  id,
  testid,
  disabled,
  title,
  lg,
}: Props) => {
  return (
    <div
      title={title}
      className={cx(styles.toggle, 'form-check', 'form-switch')}
    >
      <input
        className={cx('form-check-input', { [styles.largeInput]: lg })}
        data-testid={testid}
        aria-label="toggle"
        disabled={disabled}
        type="checkbox"
        role="switch"
        id={id}
        name={id}
        onChange={handleChange}
        checked={checked}
      ></input>
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default Toggle;
