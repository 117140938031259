import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import cx from 'classnames';

import { sendActivationEmail } from 'src/api/api';
import { avatarLabel, fullName } from 'src/components/AdminPage/Common/utils';
import BgImage from 'src/components/BgImage/BgImage';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import adminSelectors from 'src/redux/admin/admin-selectors';
import dateUtils from '../../../../../util/date';
import OrganizationRoleDropdown from '../../PillDropdowns/OrganizationRoleDropdown';
import RemoveMemberButton from '../../RemoveMemberButton/RemoveMemberButton';
import EditMemberDetailsForm from './EditMemberDetailsForm';

import styles from './MemberDetails.module.scss';

const MemberDetails = () => {
  const { t } = useTranslation();
  const organizationId = useSelector(adminSelectors.getOrganizationId);
  const iAmAdminOrOwner = useSelector(adminSelectors.iAmAdminOrOwner);
  const currentMember = useSelector(adminSelectors.getCurrentUser);

  const [isEditing, setIsEditing] = useState(false);

  const [emailStatus, setEmailStatus] = React.useState<
    undefined | 'sent' | 'loading' | 'error'
  >();

  const avatarStr = avatarLabel(currentMember);

  const name = fullName(currentMember);

  const userIsPending = !currentMember?.last_login;

  const lastActivity = userIsPending
    ? ''
    : dateUtils.relativeAbsDateFormat(currentMember?.last_access);

  const onEmailClick = () => {
    // Since sending the email is not part of a user update, it exists outside of the transaction process
    if (organizationId && currentMember) {
      setEmailStatus('loading');
      sendActivationEmail(organizationId, currentMember.id)
        .then(() => setEmailStatus('sent'))
        .catch(() => setEmailStatus('error'));
    }
  };

  const ButtonContent = React.useMemo(() => {
    switch (emailStatus) {
      case 'sent':
        return t('admin.tab_team_member_email_success');

      case 'loading':
        return <LoadingSpinner active size="md" />;
      case 'error':
        return t('admin.tab_team_member_email_failure');
      default:
        return t('admin.tab_team_member_email');
    }
  }, [emailStatus, t]);

  return (
    <div className={cx(styles.container)}>
      {currentMember?.profile_image ? (
        <BgImage
          src={currentMember?.profile_image}
          className={cx('rounded-circle me-3')}
          width="48px"
          height="48px"
          vignette
        />
      ) : (
        <div
          className={cx(
            'rounded-circle me-3 d-flex justify-content-center align-items-center',
            styles.avatar
          )}
        >
          {avatarStr}
        </div>
      )}
      <div className={cx(styles.memberFormContainer)}>
        {isEditing ? (
          <EditMemberDetailsForm
            onClose={() => {
              setIsEditing(false);
            }}
          />
        ) : (
          <>
            <h3 className={cx('m-0 mt-3')}>{name}</h3>
            <span>{currentMember?.email}</span>
            <div>
              <Button
                color={'link'}
                disabled={!iAmAdminOrOwner}
                onClick={() => setIsEditing(true)}
                data-testid={'team-member-edit-button'}
              >
                {t('common.edit')}
              </Button>
            </div>
          </>
        )}
      </div>
      <div className={cx('mt-3', { [styles.unFocused]: isEditing })}>
        {userIsPending ? (
          <div>
            <span className={cx(styles.status)}>PENDING</span>
            <br />
            <Button color={'link'} onClick={onEmailClick}>
              {ButtonContent}
            </Button>
          </div>
        ) : (
          <div>
            <span className={cx(styles.status, styles.active)}>ACTIVE</span>
            <br />
            <span>{lastActivity}</span>
          </div>
        )}
      </div>

      <div className={cx('mt-4', 'mb-4', { [styles.unFocused]: isEditing })}>
        <label className={cx(styles.orgDropdownLabel)}>
          {t('admin.tab_team_member_list_column_2')}
        </label>
        {currentMember && <OrganizationRoleDropdown member={currentMember} />}
      </div>

      {currentMember && (
        <RemoveMemberButton
          verboseLabel
          classNames={cx(styles.removeButton, {
            [styles.unFocused]: isEditing,
          })}
          member={currentMember}
        />
      )}
    </div>
  );
};

export default MemberDetails;
