import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import adminSelectors from 'src/redux/admin/admin-selectors';
import { userTransaction } from 'src/redux/admin/admin-slice';
import authSelectors from 'src/redux/auth/auth-selectors';
import { generateTransaction } from 'src/redux/redux-helpers';
import { StoreState } from 'src/redux/store';
import { UserTransactions } from 'src/types/admin';
import { Role, User } from 'src/types/auth';
import { CRUD } from 'src/types/core';
import { CommunityMember } from 'src/types/forum';
import { CommunityRole } from 'src/types/organization';
import PillDropdown from './PillDropdown';

interface CommunityRoleDropdownProps {
  member: User | CommunityMember;
  communityId?: number;
}

interface StateProps {
  user: User;
}

type Props = StateProps & CommunityRoleDropdownProps;

const mapStateToProps = (state: StoreState): StateProps => ({
  user: authSelectors.getUser(state),
});

const CommunityRoleDropdown = ({ user, member, communityId }: Props) => {
  const dispatch = useDispatch();
  const iAmCommunityAdmin = useSelector((state: StoreState) =>
    adminSelectors.iAmCommunityAdmin(state, communityId)
  );
  const activeCommunity = useSelector(adminSelectors.getActiveCommunity);
  const transactions = useSelector((state) =>
    adminSelectors.getUserTransactionsById(state as StoreState, member.id)
  );
  const [transaction, setTransaction] = React.useState<
    UserTransactions | undefined
  >();

  const USER_MANAGEMENT_FLAG = user.flags?.user_management_changes;

  const targetCommunityId = communityId || activeCommunity?.id;

  const roleFilter = (role: Role) => {
    return (
      role.community_id === targetCommunityId &&
      Object.values(CommunityRole)
        .map((role) => role.toLowerCase())
        .includes(role.role_type as CommunityRole)
    );
  };

  const getRolePriority = (role: Role) =>
    Object.values(CommunityRole)
      // remove host role and lowercase the rest
      .map((role) => role.toLowerCase())
      .indexOf(role.role_type as CommunityRole);

  // TODO: this is broken until we get community roles on the organization members result
  const sortedCommunityRoles =
    member?.roles
      ?.filter(roleFilter)
      ?.sort((a, b) => getRolePriority(a) - getRolePriority(b)) ?? [];

  const communityRole = sortedCommunityRoles[0]?.role_type || 'member';

  useEffect(() => {
    if (transaction) {
      const matchingTransaction = transactions.filter(
        (item) => item.id === transaction.id
      );
      if (!matchingTransaction.length) {
        setTransaction(undefined);
      } else {
        setTransaction(matchingTransaction[0]);
      }
    }
  }, [transactions, transaction]);

  const isCommunityOwner =
    communityRole.toLowerCase() === CommunityRole.owner.toLowerCase();

  const disabled = isCommunityOwner || !iAmCommunityAdmin;

  const handleAccessLevelChange = (communityRole: CommunityRole) => {
    const transaction: UserTransactions = generateTransaction({
      type: 'update_user_community_role',
      crud: CRUD.put,
      data: {
        id: member.id,
        communityId: targetCommunityId,
        roles: [
          {
            role_type: communityRole.toLowerCase(),
          },
        ],
      },
    });

    setTransaction(transaction);
    dispatch(userTransaction(transaction));
  };

  return (
    <PillDropdown
      dropdownItems={[
        {
          value: CommunityRole.owner,
          hide: true,
        },
        {
          value: CommunityRole.admin,
          hide: false,
        },
        {
          value: CommunityRole.sensemaker,
          hide: USER_MANAGEMENT_FLAG ? true : false,
        },
        {
          value: CommunityRole.host,
          hide: false,
        },
        {
          value: CommunityRole.member,
          hide: false,
        },
      ]}
      selectedValue={communityRole}
      onSelect={(value) => handleAccessLevelChange(value as CommunityRole)}
      loading={!!transaction && !transaction.error}
      disable={disabled}
      testId={`member-${member.id}-access-level`}
    />
  );
};

export default connect(mapStateToProps)(CommunityRoleDropdown);
