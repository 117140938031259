import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cx from 'classnames';

import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import SearchInput from 'src/components/SearchInput/SearchInput';
import { sortUsers } from 'src/util/user';

import styles from './SearchableTable.module.scss';

type SearchableTableProps<T = any> = {
  queryFilter: (query: string, val: T) => boolean;
  values: T[];
  header: React.FC;
  table: React.FC<{ values: T[] }>;
  action?: React.FC;
  loading?: boolean;
};

const SearchableTable = ({
  queryFilter,
  values,
  header,
  table,
  action,
  loading,
}: SearchableTableProps) => {
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState('');

  const processedValues = useMemo(() => {
    const filteredValues = searchQuery
      ? values.filter((m) => queryFilter(searchQuery, m))
      : values;

    return [...filteredValues].sort(sortUsers);
  }, [searchQuery, values, queryFilter]);

  const Header = header;
  const Table = table;
  const Action = action;

  return (
    <div className={cx(styles.container)}>
      {loading ? (
        <div className="w-100 text-center">
          <LoadingSpinner thick size="lg" active theme="dark" />
        </div>
      ) : (
        <div>
          <div
            className={cx(
              'd-flex flex-row flex-wrap align-items-center justify-content-between mb-4',
              styles.headerContainer
            )}
          >
            <Header />
            <div className="flex-row d-flex flex-wrap h-100">
              <div style={{ width: '350px', margin: 'auto' }}>
                <SearchInput
                  initialSearchQuery={searchQuery || ''}
                  shape="round"
                  placeholder={t('common.search')}
                  size="lg"
                  onSearch={setSearchQuery}
                  onClear={() => setSearchQuery('')}
                />
              </div>
              {Action && <Action />}
            </div>
          </div>
          {searchQuery && (
            <Trans
              i18nKey={
                processedValues.length > 0
                  ? 'search.results_description'
                  : 'search.results_description_none'
              }
              values={{
                query: searchQuery,
              }}
              components={{
                1: <b />,
              }}
            />
          )}
          {processedValues.length > 0 ? (
            <Table values={processedValues} />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default SearchableTable;
