import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParams } from 'use-query-params';

import AddMember from './AddMember/AddMember';
import TeamList from './TeamList/TeamList';
import ViewMember from './ViewMember/ViewMember';

export type TeamPageName = 'add' | 'view';

const ManageTeam = () => {
  const { t } = useTranslation();
  const [query] = useQueryParams({
    page: StringParam,
  });
  const { page: activePage } = query;

  switch (activePage as TeamPageName) {
    case 'add':
      return <AddMember />;
    case 'view':
      return <ViewMember backToListLabel={t('admin.go_to_team')} />;
    default:
      return <TeamList />;
  }
};

export default ManageTeam;
