import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import adminSelectors from 'src/redux/admin/admin-selectors';
import { userTransaction } from 'src/redux/admin/admin-slice';
import authSelectors from 'src/redux/auth/auth-selectors';
import { generateTransaction } from 'src/redux/redux-helpers';
import { StoreState } from 'src/redux/store';
import { UserTransactions } from 'src/types/admin';
import { User } from 'src/types/auth';
import { CRUD } from 'src/types/core';
import { CommunityMember } from 'src/types/forum';
import { OrganizationRole } from 'src/types/organization';
import PillDropdown from './PillDropdown';

interface OrganizationRoleDropdownProps {
  member: User | CommunityMember;
}

interface StateProps {
  user: User;
}

type Props = StateProps & OrganizationRoleDropdownProps;

const mapStateToProps = (state: StoreState): StateProps => ({
  user: authSelectors.getUser(state),
});

const OrganizationRoleDropdown = ({ user, member }: Props) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(adminSelectors.getOrganizationId);
  const iAmAdminOrOwner = useSelector(adminSelectors.iAmAdminOrOwner);
  const transactions = useSelector((state) =>
    adminSelectors.getUserTransactionsById(state as StoreState, member.id)
  );
  const [transaction, setTransaction] = React.useState<
    UserTransactions | undefined
  >();

  const USER_MANAGEMENT_FLAG = user.flags?.user_management_changes;

  const organizationRole =
    member.roles.filter((role) => !!role.organization_id)[0]?.role_type ||
    'member';

  React.useEffect(() => {
    if (transaction) {
      const matchingTransaction = transactions.filter(
        (item) => item.id === transaction.id
      );
      if (!matchingTransaction.length) {
        setTransaction(undefined);
      } else {
        setTransaction(matchingTransaction[0]);
      }
    }
  }, [transactions, transaction]);

  const handleAccessLevelChange = (organizationRole: OrganizationRole) => {
    const transaction: UserTransactions = generateTransaction({
      type: 'update_user_organization_role',
      crud: CRUD.put,
      data: {
        id: member.id,
        roles:
          organizationRole === OrganizationRole.member
            ? []
            : [
                {
                  role_type: organizationRole.toLowerCase(),
                  organization_id: organizationId,
                },
              ],
      },
    });

    setTransaction(transaction);
    dispatch(userTransaction(transaction));
  };

  const isOwner =
    organizationRole.toLowerCase() === OrganizationRole.owner.toLowerCase();
  const disabled = isOwner || !iAmAdminOrOwner;

  return (
    <PillDropdown
      dropdownItems={[
        {
          value: OrganizationRole.owner,
          hide: true,
        },
        {
          value: OrganizationRole.admin,
          hide: false,
        },
        {
          value: OrganizationRole.sensemaker,
          hide: false,
        },
        {
          value: OrganizationRole.member,
          hide: false,
        },
      ]}
      selectedValue={organizationRole}
      onSelect={(value) => handleAccessLevelChange(value as OrganizationRole)}
      loading={!!transaction && !transaction.error}
      disable={disabled}
      testId={`member-${member.id}-access-level`}
    />
  );
};

export default connect(mapStateToProps)(OrganizationRoleDropdown);
