import { call, put, SagaReturnType, select } from 'redux-saga/effects';

import * as api from 'src/api/api';
import { canAccessAdminCommunity } from 'src/components/AdminPage/AdminPage';
import {
  AddCommunityMemberTransaction,
  CreateUserTransaction,
  RemoveCommunityMemberTransaction,
  RemoveUserTransaction,
  UpdateUserTransaction,
} from 'src/types/admin';
import { User } from 'src/types/auth';
import { CRUD } from 'src/types/core';
import { Community } from 'src/types/forum';
import authSelectors from '../auth/auth-selectors';
import { editUserSuccess } from '../auth/auth-slice';
import adminSelectors from './admin-selectors';
import {
  getCommunityMembersSuccess,
  removeUserTransaction,
  userTransactionFailure,
  userTransactionSuccess,
} from './admin-slice';

export function* processOrganizationRoleUpdates(
  transaction: UpdateUserTransaction
) {
  const organizationId: ReturnType<typeof adminSelectors.getOrganizationId> =
    yield select(adminSelectors.getOrganizationId);
  if (organizationId == null) {
    yield put(
      userTransactionFailure({
        transaction,
        error: {
          status: 'fail',
          message: 'Need an organization id',
        },
      })
    );
  } else {
    const { id: userId, roles } = transaction.data;

    if (roles) {
      const role_types: string[] = roles.map((r) => r.role_type);
      if (transaction.crud === CRUD.post && role_types.length > 0) {
        yield call(api.postUserRoles, userId, { role_types, organizationId });
      }
      if (transaction.crud === CRUD.put) {
        yield call(api.putUserRoles, userId, { role_types, organizationId });
      }
      if (transaction.crud === CRUD.delete && role_types.length > 0) {
        yield call(api.deleteUserRoles, userId, { role_types, organizationId });
      }

      const updatedUser: SagaReturnType<typeof api.getUserInOrganizationById> =
        yield call(api.getUserInOrganizationById, organizationId, userId);

      // Put updated user into array of all users
      yield put(userTransactionSuccess({ updatedUser, transaction }));
      // Put updated user into auth in case it is current user
      yield put(editUserSuccess({ updatedUser }));
    }
  }
}

export function* processCommunityRoleUpdates(
  transaction: UpdateUserTransaction
) {
  const organizationId: ReturnType<typeof adminSelectors.getOrganizationId> =
    yield select(adminSelectors.getOrganizationId);
  if (organizationId == null) {
    yield put(
      userTransactionFailure({
        transaction,
        error: {
          status: 'fail',
          message: 'Need an organization id',
        },
      })
    );
  } else {
    const targetCommunityId =
      transaction.data.communityId ||
      (
        (yield select(adminSelectors.getActiveCommunity)) as ReturnType<
          typeof adminSelectors.getActiveCommunity
        >
      )?.id;
    if (!targetCommunityId) {
      yield put(
        userTransactionFailure({
          transaction,
          error: {
            status: 'fail',
            message: 'Need a community id',
          },
        })
      );
    } else {
      const { id: userId, roles } = transaction.data;

      if (roles) {
        const role_types: string[] = roles.map((r) => r.role_type);
        if (transaction.crud === CRUD.post && role_types.length > 0) {
          yield call(api.postUserRoles, userId, {
            role_types,
            communityId: targetCommunityId,
          });
        }
        if (transaction.crud === CRUD.put) {
          yield call(api.putUserRoles, userId, {
            role_types,
            communityId: targetCommunityId,
          });
        }
        if (transaction.crud === CRUD.delete && role_types.length > 0) {
          yield call(api.deleteUserRoles, userId, {
            role_types,
            communityId: targetCommunityId,
          });
        }

        const updatedCommunityMembers: SagaReturnType<
          typeof api.getCommunityMembers
        > = yield call(api.getCommunityMembers, {
          communityId: targetCommunityId,
        });

        // Update community users in state
        yield put(getCommunityMembersSuccess(updatedCommunityMembers));
        yield put(removeUserTransaction(transaction));
      }
    }
  }
}

export function* processCatalogRoleUpdates(transaction: UpdateUserTransaction) {
  const { id: userId, roles, catalogId } = transaction.data;
  const role_types = roles?.map((r) => r.role_type);

  const organizationId: ReturnType<typeof adminSelectors.getOrganizationId> =
    yield select(adminSelectors.getOrganizationId);

  if (!(catalogId && organizationId && userId)) {
    yield put(
      userTransactionFailure({
        transaction,
        error: {
          status: 'fail',
          message: 'Need a catalog, organization, and user id',
        },
      })
    );
  } else {
    if (role_types) {
      if (transaction.crud === CRUD.put && role_types.length > 0) {
        yield call(api.putUserRoles, userId, {
          role_types,
          catalogId,
        });
      }

      if (transaction.crud === CRUD.delete && role_types.length > 0) {
        yield call(api.deleteUserRoles, userId, {
          role_types,
          catalogId,
        });
      }

      const updatedUser: SagaReturnType<typeof api.getUserInOrganizationById> =
        yield call(api.getUserInOrganizationById, organizationId, userId);

      // Put updated user into array of all users
      yield put(userTransactionSuccess({ updatedUser, transaction }));
      // Put updated user into auth in case it is current user
      yield put(editUserSuccess({ updatedUser }));
    }
  }
}

export function* processCollectionRoleUpdates(
  transaction: UpdateUserTransaction
) {
  const organizationId: ReturnType<typeof adminSelectors.getOrganizationId> =
    yield select(adminSelectors.getOrganizationId);
  const { id: userId, roles, collectionId } = transaction.data;
  const role_types = roles?.map((r) => r.role_type);

  if (collectionId == null || organizationId == null) {
    yield put(
      userTransactionFailure({
        transaction,
        error: {
          status: 'fail',
          message: 'Need a collection or organization id',
        },
      })
    );
  } else {
    // NOTE we don't check for length here because we can pass an empty array to clear out roles
    // just check to make sure it is defined
    if (role_types) {
      if (transaction.crud === CRUD.post) {
        yield call(api.postUserRoles, userId, { role_types, collectionId });
      }
      if (transaction.crud === CRUD.put) {
        yield call(api.putUserRoles, userId, { role_types, collectionId });
      }
      if (transaction.crud === CRUD.delete) {
        yield call(api.deleteUserRoles, userId, { role_types, collectionId });
      }

      const updatedUser: SagaReturnType<typeof api.getUserInOrganizationById> =
        yield call(api.getUserInOrganizationById, organizationId, userId);

      // Put updated user into array of all users
      yield put(userTransactionSuccess({ updatedUser, transaction }));
      // Put updated user into auth in case it is current user
      yield put(editUserSuccess({ updatedUser }));
    }
  }
}

export function* processUserUpdates(transaction: UpdateUserTransaction) {
  const updatedUser: SagaReturnType<typeof api.putUserEdit> = yield call(
    api.putUserEdit,
    transaction.data
  );
  // Put updated user into array of all users
  yield put(userTransactionSuccess({ updatedUser, transaction }));
  // Put updated user into auth in case it is currnt user
  yield put(editUserSuccess({ updatedUser }));
}

export function* processAddUser(transaction: CreateUserTransaction) {
  const organizationId: ReturnType<typeof adminSelectors.getOrganizationId> =
    yield select(adminSelectors.getOrganizationId);
  if (organizationId != null) {
    const users: ReturnType<typeof adminSelectors.getUsers> = yield select(
      adminSelectors.getUsers
    );
    const existingUser = users.filter(
      (user) => user.email === transaction.data.email
    )[0];
    let createdUser: User;
    if (!existingUser) {
      createdUser = yield call(
        api.postUserCreation,
        transaction.data,
        organizationId
      );
    } else {
      createdUser = existingUser;
    }
    // Put newly created user into array of all users
    yield put(
      userTransactionSuccess({
        updatedUser: createdUser,
        transaction: transaction,
      })
    );
  }
}

export function* processLinkUser(
  transaction: CreateUserTransaction,
  userId: number
) {
  const organizationId: ReturnType<typeof adminSelectors.getOrganizationId> =
    yield select(adminSelectors.getOrganizationId);

  if (organizationId != null) {
    // Attempt to link user
    yield call(
      api.postLinkUserToOrganization,
      organizationId,
      transaction.data.email,
      userId,
      transaction.data.is_admin
    );

    const linkedUser: SagaReturnType<typeof api.getUserInOrganizationById> =
      yield call(api.getUserInOrganizationById, organizationId, userId);
    // Put updated user into array of all users
    yield put(userTransactionSuccess({ updatedUser: linkedUser, transaction }));
  }
}

export function* processRemoveUser(transaction: RemoveUserTransaction) {
  const organizationId: ReturnType<typeof adminSelectors.getOrganizationId> =
    yield select(adminSelectors.getOrganizationId);
  if (organizationId != null) {
    yield call(
      api.removeUserFromOrganization,
      transaction.data.id,
      organizationId
    );
    // Put updated user into array of all users
    yield put(
      userTransactionSuccess({
        updatedUser: { id: transaction.data.id },
        transaction,
      })
    );
  }
}

export function* processRemoveCommunityMember(
  transaction: RemoveCommunityMemberTransaction
) {
  const organizationId: ReturnType<typeof adminSelectors.getOrganizationId> =
    yield select(adminSelectors.getOrganizationId);

  const community: ReturnType<typeof adminSelectors.getActiveCommunity> =
    yield select(adminSelectors.getActiveCommunity);

  if (community != null && organizationId != null) {
    yield call(api.removeCommunityMember, transaction.data.id, community.id);

    const updatedUser: SagaReturnType<typeof api.getUserInOrganizationById> =
      yield call(
        api.getUserInOrganizationById,
        organizationId,
        transaction.data.id
      );

    // Put updated user into array of all users and update state.admin.communityMembers
    yield put(
      userTransactionSuccess({
        updatedUser,
        transaction,
      })
    );
  }
}

export function* processAddCommunityMember(
  transaction: AddCommunityMemberTransaction
) {
  const organizationId: ReturnType<typeof adminSelectors.getOrganizationId> =
    yield select(adminSelectors.getOrganizationId);

  const community: ReturnType<typeof adminSelectors.getActiveCommunity> =
    yield select(adminSelectors.getActiveCommunity);

  if (community != null && organizationId != null) {
    yield call(api.addCommunityMember, transaction.data.id, community.id);

    const updatedUser: SagaReturnType<typeof api.getUserInOrganizationById> =
      yield call(
        api.getUserInOrganizationById,
        organizationId,
        transaction.data.id
      );

    // Put updated user into array of all users and update state.admin.communityMembers
    yield put(
      userTransactionSuccess({
        updatedUser,
        transaction,
      })
    );
  }
}

export function* getDefaultCommunity(communities: Community[]) {
  const currentUser: ReturnType<typeof authSelectors.getUser> = yield select(
    authSelectors.getUser
  );

  let defaultCommunity: Community | undefined;

  communities.forEach((community) => {
    if (canAccessAdminCommunity(community.id, currentUser)) {
      defaultCommunity = community;
    }
  });

  return defaultCommunity;
}
