import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import SearchableTable from 'src/components/AdminPage/Common/SearchableTable';
import Button from 'src/components/core/Button/Button';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { matchMember } from '../../Common/utils';
import AddCommunityMemberTable from './AddCommunityMemberTable';

const Header = () => {
  const { t } = useTranslation();

  const [, setQuery] = useQueryParams({
    page: StringParam,
    userId: NumberParam,
  });

  return (
    <>
      <div>
        <Button
          color={'link'}
          onClick={() =>
            setQuery({ userId: undefined, page: undefined }, 'pushIn')
          }
          icon={['far', 'arrow-left']}
          content={t('admin.go_to_community')}
        />
        <div className="d-flex flex-row">
          <span>{t('admin.add_community_members_helper')}</span>
        </div>
      </div>
    </>
  );
};

const AddCommunityMember = () => {
  const nonCommunityMembers = useSelector(
    adminSelectors.getNonCommunityMembers
  );

  const isLoadingUsers = useSelector(adminSelectors.isLoadingUsers);

  return (
    <SearchableTable
      values={nonCommunityMembers}
      queryFilter={matchMember}
      header={Header}
      table={AddCommunityMemberTable}
      loading={isLoadingUsers}
    />
  );
};

export default AddCommunityMember;
