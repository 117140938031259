import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { Descendant } from 'slate';
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
} from 'slate-react';

import { useSeekAudio } from 'src/contexts/GlobalAudioContext';
import transcriptEditSelectors from 'src/redux/transcript-edit/transcript-edit-selectors';
import {
  clearRedactionTime,
  saveTranscriptEdit,
} from 'src/redux/transcript-edit/transcript-edit-slice';
import { Annotation, Snippet } from 'src/types/conversation';
import { EditableParagraph } from 'src/types/transcript';
import {
  getRange,
  secondsToMilliseconds,
  slateToSnippets,
} from 'src/util/slate';
import EditBar from './EditBar/EditBar';
import TimedText from './TimedText/TimedText';
import { useAccessibility } from './useAccessibility';
import useTranscriptEditor from './useTranscriptEditor';
import { useTranscriptScroll } from './useTranscriptScroll';

import styles from './EditableTranscript.module.scss';

interface BaseProps {
  snippets: Snippet[];
  isStaff: boolean;
  redactOnly?: boolean;
  handlePlayAtTime: (time: number) => void;
  handleSeek: (time: number) => void;
  scroll: (time: number, onScrollCompletion?: () => void) => void;
  setSearchIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  onSave: (value: Descendant[]) => void;
  onRedact: (
    value: Descendant[],
    annotation?: Pick<Annotation, 'audio_start_offset' | 'audio_end_offset'>
  ) => void;
  cleanRedaction: () => void;
}

// Interval in miliseconds
const AUDIO_RANGE_INTERVAL = 100;

const SlateTranscript = React.forwardRef<HTMLDivElement, BaseProps>(
  (
    {
      snippets,
      isStaff,
      redactOnly,
      handlePlayAtTime,
      handleSeek,
      scroll,
      setSearchIndex,
      onSave,
      onRedact,
      cleanRedaction,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const saveError = useSelector(transcriptEditSelectors.getError);
    const isSaving = useSelector(transcriptEditSelectors.isSaving);
    const isResetting = useSelector(transcriptEditSelectors.isResetting);
    const isRedacting = useSelector(transcriptEditSelectors.isRedacting);
    const saveTime = useSelector(transcriptEditSelectors.getSaveTime);
    const redactionTime = useSelector(transcriptEditSelectors.getRedactionTime);

    React.useEffect(() => {
      // Used to place the user back at the location of a redaction after a redaction is completed
      if (redactionTime) {
        scroll(redactionTime);
        handleSeek(redactionTime);
        cleanRedaction();
      }
    }, [redactionTime]);

    const {
      editor,
      value,
      speakerOptions,
      changeSpeakerName,
      handleChange,
      onPaste,
      searchParams,
      setSearchParams,
      handleNextSearch,
      handlePreviousSearch,
      handleReplace,
      searchIndex,
      totalSearchResults,
      decorate,
      canUndo,
      canRedo,
      undo,
      redo,
      redactionParams,
      handleRedactions,
    } = useTranscriptEditor(snippets, onSave, onRedact, scroll);
    React.useEffect(() => {
      if (searchParams.isSearching) {
        setSearchIndex(searchIndex);
      } else {
        setSearchIndex(undefined);
      }
    }, [searchIndex, searchParams.isSearching, setSearchIndex]);

    const { handleKeyPress } = useAccessibility(value, editor, ref);

    const renderElement = React.useCallback(
      (props: RenderElementProps) => {
        switch (props.element.type) {
          case 'timedText':
            return (
              <TimedText
                {...props}
                onSpeakerChange={(data) => {
                  changeSpeakerName(
                    data,
                    ReactEditor.findPath(editor, props.element)
                  );
                }}
                onPlay={handlePlayAtTime}
                speakerOptions={speakerOptions}
              />
            );
          default:
            return <p {...props} />;
        }
      },
      [handlePlayAtTime, changeSpeakerName, speakerOptions, editor]
    );

    const renderLeaf = React.useCallback(
      ({ attributes, children, leaf }: RenderLeafProps) => {
        const range =
          ',' +
          getRange(
            secondsToMilliseconds(leaf.chunk_start_offset),
            secondsToMilliseconds(leaf.chunk_end_offset),
            AUDIO_RANGE_INTERVAL
          ).join(',') +
          ',';
        return (
          <span
            {...attributes}
            {...{ 'data-audio-range': range }}
            {...{ 'data-starttime': leaf.audio_start_offset }}
            {...(leaf.searchHighlight && {
              'data-slate-testid': 'search-highlighted',
            })}
            {...(leaf.isFocusedSearchHighlight && {
              'data-focused-search': true,
              'data-slate-testid': 'search-focused',
            })}
            {...(leaf.isSelected && { 'data-slate-testid': 'selected-text' })}
            data-testid={`slate-${leaf.text.trim()}-${leaf.audio_start_offset}`}
            className={cx(styles.leaf, {
              [styles.emphasize]: leaf.emphasize,
              [styles.searchHighlight]: leaf.searchHighlight,
              [styles.searchFocusedHighlight]: leaf.isFocusedSearchHighlight,
              [styles.selected]: leaf.isSelected,
            })}
            onClick={() => handleSeek(leaf.audio_start_offset)}
          >
            {children}
          </span>
        );
      },
      [handleSeek]
    );

    const paste = React.useCallback(
      (event: React.ClipboardEvent<HTMLDivElement>) => {
        const text = event.clipboardData.getData('text/plain');
        onPaste(text);
        event.preventDefault();
      },
      [onPaste]
    );

    return (
      <div
        data-testid="editable-transcript"
        className={styles.editTranscriptParentContainer}
        data-ignore-click={true}
      >
        <EditBar
          undo={undo}
          redo={redo}
          canUndo={canUndo}
          canRedo={canRedo}
          saveTime={saveTime}
          isSaving={isSaving || isResetting}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          onNextSearch={handleNextSearch}
          onPreviousSearch={handlePreviousSearch}
          onReplace={handleReplace}
          searchIndex={searchIndex}
          totalSearchResults={totalSearchResults}
          error={saveError}
          isStaff={isStaff}
          handleRedactions={handleRedactions}
          redactionParams={redactionParams}
          redactOnly={redactOnly}
        />
        <div className={styles.editTranscriptContainer} ref={ref}>
          {value.length > 0 &&
            (isResetting ? (
              <div data-testid="editor-resetting" className={styles.resetting}>
                <span>{t('transcript_editor.resetting')}</span>
              </div>
            ) : isRedacting ? (
              <div data-testid="editor-redacting" className={styles.resetting}>
                <span>{t('transcript_editor.redacting')}</span>
              </div>
            ) : (
              <Slate editor={editor} value={value} onChange={handleChange}>
                <Editable
                  renderElement={renderElement}
                  renderLeaf={renderLeaf}
                  onPaste={paste}
                  decorate={decorate}
                  aria-label="Transcript Editor"
                  onKeyDown={handleKeyPress}
                  readOnly={redactOnly}
                />
              </Slate>
            ))}
        </div>
      </div>
    );
  }
);
const MemoSlateTranscript = React.memo(SlateTranscript);
(MemoSlateTranscript as any).whyDidYouRender = false;

export const EditableTranscript = React.forwardRef<
  HTMLDivElement,
  Pick<BaseProps, 'snippets' | 'isStaff' | 'redactOnly'>
>(({ snippets, isStaff, redactOnly }, ref) => {
  const dispatch = useDispatch();
  const { seekTime, activatedPlay, isActive, seek, pause, isPlaying } =
    useSeekAudio({
      initialIsActive: true,
    });
  const [currentTime, setCurrentTime] = React.useState<number>();
  // If transcript should scroll with audio or not
  const [autoScroll, setAutoScroll] = React.useState(true);
  const [searchIndex, setSearchIndex] = React.useState<number>();
  const [allowScrollInterrupt, setAllowScrollInterrupt] = React.useState(false);

  // wrap in useCallback to prevent rerender which causes transcript to
  // scroll back to the top when a highlight is created
  const handleScrollInterruption = React.useCallback(() => {
    if (allowScrollInterrupt && searchIndex != null) {
      setAutoScroll(false);
    }
  }, [allowScrollInterrupt, searchIndex]);

  React.useEffect(() => {
    if (isActive) {
      setAllowScrollInterrupt(true);
    }
  }, [isActive]);

  React.useEffect(() => {
    if (searchIndex) {
      setAutoScroll(true);
    }
  }, [searchIndex]);

  const scroll = useTranscriptScroll(
    ref as React.RefObject<HTMLElement>,
    seekTime,
    searchIndex,
    handleScrollInterruption,
    autoScroll
  );

  React.useEffect(() => {
    if (seekTime) {
      const seekTimeInMilliseconds = secondsToMilliseconds(seekTime);
      setCurrentTime(
        getRange(
          seekTimeInMilliseconds,
          seekTimeInMilliseconds + AUDIO_RANGE_INTERVAL,
          AUDIO_RANGE_INTERVAL
        )[0]
      );
    }
  }, [seekTime]);

  const handleSeek = React.useCallback(
    (seekTime: number) => {
      pause();
      seek(seekTime, false);
    },
    [seek, pause]
  );

  const handlePlayAtTime = React.useCallback(
    (time: number) => {
      if (isPlaying) {
        handleSeek(time);
      } else {
        activatedPlay(time);
      }
    },
    [activatedPlay, handleSeek, isPlaying]
  );

  const handleSave = React.useCallback(
    (value: Descendant[]) => {
      const newSnippets = slateToSnippets(value as EditableParagraph[]);
      if (newSnippets) {
        dispatch(saveTranscriptEdit({ snippets: newSnippets }));
      }
    },
    [dispatch]
  );

  const handleRedaction = React.useCallback(
    (
      value: any,
      annotation?: Pick<Annotation, 'audio_start_offset' | 'audio_end_offset'>
    ) => {
      const newSnippets = slateToSnippets(value);
      if (newSnippets) {
        dispatch(
          saveTranscriptEdit({
            snippets: newSnippets,
            redaction: true,
            ...annotation,
          })
        );
      }
    },
    [dispatch]
  );

  const cleanRedaction = React.useCallback(() => {
    dispatch(clearRedactionTime());
  }, [dispatch]);

  // Sort snippets so that they appear approriately in the UI
  const sortedSnippets = React.useMemo(
    () =>
      snippets.sort(
        (a, b) => a.index_in_conversation - b.index_in_conversation
      ),
    [snippets]
  );

  return (
    <>
      <style scoped>
        {`/* This is not our usual way to define a style, however, since we want the style definition to update with
          every change in seektime (and we do not want the editor to update as well), we use this css trick.
          */
             [data-slate-leaf="true"][data-audio-range*=",${currentTime},"]{
                  background: #ffee58;
              }
          `}
      </style>
      <MemoSlateTranscript
        snippets={sortedSnippets}
        isStaff={isStaff}
        redactOnly={redactOnly}
        handlePlayAtTime={handlePlayAtTime}
        handleSeek={handleSeek}
        scroll={scroll}
        setSearchIndex={setSearchIndex}
        onSave={handleSave}
        onRedact={handleRedaction}
        cleanRedaction={cleanRedaction}
        ref={ref}
      />
    </>
  );
});

export default EditableTranscript;
